import React from "react";
import { Button, Drawer, Space, Tooltip} from "antd";
import { IRejectDrawerProps } from 'models/interfaces/RejectMail/rejectMail.model'
import TextArea from "antd/lib/input/TextArea";


const RejectDrawer:React.FC<IRejectDrawerProps> = 
({rejectData, open, setOpen, handleChange, submitRejectData, cancleReject, selectedElem, title}) => {

  return (
    <Drawer
        title={title}
        placement="right"
        onClose={() => setOpen(false)}
        visible={open}
        className="filterDrawerWrap drawerCustom"
        closeIcon={
          <Tooltip title="Close Filter Drawer" placement="left">
            <i className="da icon-arrow-right-dark"></i>
          </Tooltip>
        }
      >
    <div className="flex-1">
        <div className="inputWrap">
          <label>Reason</label>
          <TextArea 
            style={{color: 'black'}}
            onChange={handleChange} 
            value={rejectData}
            placeholder="Reason for rejection"
          />
        </div>
      </div>  
        <Space wrap className="btnsRight">
          <Button type="link" onClick={cancleReject} className="linkBtn">Cancel</Button>
          <Button type="primary" onClick={() => submitRejectData('rejected', {...selectedElem, rejectReason: rejectData})}>Submit</Button>
        </Space>

      </Drawer>
  );
};

export default RejectDrawer;




