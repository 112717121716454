import { Collapse, Layout, UploadFile } from 'antd';
import { usePageTitle } from 'hooks';
import { useApiServices } from 'hooks/useApiServices';
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import UploadComponent from 'views/Biometric/UploadComponent';
import { Constants } from 'views/constants';
import daLogo from 'assets/images/da-logo.svg';

const { Panel } = Collapse;
const UploadOnboardingDocument = () => {
  usePageTitle(Constants.UPLOAD_DOCUMENTS_TITLE);
  const [openFolders, setOpenedFolders] = useState<string[]>([])
  const { fetchData, updateData, isLoading, isError, data: candidateData } = useApiServices()
  const { id } = useParams<{ id: string }>();
  const isDisabled = () => isLoading;

  const pageData: { documents: any[], folders: string[], joinee_id: string } = useMemo(() => {
    if(!candidateData) return { documents: [], folders: [], joinee_id: '' };

    const { data } = candidateData;
    if(data)
      setOpenedFolders(data?.folders?.map((folder: any, index: number) => index + 1))
    return { documents: data?.documents || [], folders: data?.folders || [], joinee_id: data?.joinee_id || '' }
  }, [candidateData]);


  const handleUpload = async (fileList: UploadFile[], data: string) => {
    const formData = new FormData();
    formData.append('folder_id', data)
    formData.append('joinee_id', pageData?.joinee_id)
    formData.append('token', id)
    
    if (fileList) {
      fileList.forEach((file) => {
        formData.append('files', file as unknown as Blob)
      })
    }

    await updateData('UPLOAD_ONBOARDING_DOCUMENTS', formData);
    await fetchData('GET_CANDIDATE_DATA', { token: id });
  };

  const handleDeleteFile = async (fileName: string, folderId: number, joineeId: number) => {
    await updateData('DELETE_ONBOARDING_DOCUMENTS', {file_name: fileName, folder_id: folderId, joinee_id: joineeId, token: id});
    await fetchData('GET_CANDIDATE_DATA', { token: id });
  }


  useEffect(() => {
    fetchData('GET_CANDIDATE_DATA', { token: id })
  }, [fetchData, id])

  return (
    <Layout className="mainInnerLayout full-screen uploadDocument">
      {
        isError ?
          <h3>{isError?.error?.response?.data.msg}</h3>
          :

          <LoadingSpinner isLoading={isLoading}>
            {
              isLoading ?
                <div className='full-screen'></div>
                :
                <>
                  <div className='upload-file-header'><img src={daLogo} alt='Digital Avenues' /></div>
                  <div className="innerHeader headerSingle">
                    <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                      <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                        <h3>{Constants.UPLOAD_DOCUMENTS}</h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Collapse
                      className='upload-file-collapse'
                      activeKey={openFolders}
                      onChange={(keyArray) => {
                        setOpenedFolders(typeof (keyArray) == 'string'? [keyArray] : keyArray);
                      }}
                    >

                      {
                        pageData?.folders.map((data, index) => (
                          <Panel header={data} key={index + 1} style={{ fontSize: '1.2rem' }}>
                            {
                              <ul className='uploadedFileList'>
                                {
                                  pageData?.documents[index + 1]?.map((curr_file: any) => {
                                    return (
                                      <li key={curr_file.id}>
                                        <span>{curr_file.file_name}</span>
                                        <i className='da icon-tick-circle icon-tick-cross-circle' onClick={() => handleDeleteFile(curr_file.file_name, index+1, Number(pageData?.joinee_id))}></i>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            }
                            <UploadComponent
                              isDisabled={isDisabled}
                              handleUpload={handleUpload}
                              acceptedTypes='.pdf, .xlxs, .xls, .csv, .png'
                              multiple={true}
                              data={index + 1}
                            />
                          </Panel>
                        ))
                      }
                    </Collapse>
                  </div>
                </>
            }
          </LoadingSpinner>
      }
    </Layout>
  )
}

export default UploadOnboardingDocument;
