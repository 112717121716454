import axios from "axios";
import { RoutesEnum } from "models/enums";
import {
  EmployeeInfo,
  JoineeAccessInfo,
  JoinersListInfo,
  profileImage

} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class JoinersListOnboardingService {
  public getJoinersList = async (joinersListQueryDto: {}) => {
    return axiosClient.get<IApiBaseResponse<JoinersListInfo>>(
      RoutesEnum.ONBOARDING_JOINERS_LIST,
      {
        params: joinersListQueryDto,
      }
    );
  };

  public getJoinersFormDropdowns = async (dropdownQueryDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_FORM_DROPDOWN,
      {
        params: dropdownQueryDto,
      }
    );
  };

  public getEmailExistance = async (emailDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_OFFICE_EMAIL_CHECK,
      {
        params: emailDto,
      }
    );
  };

  public updateJoineeStatus = async (joineeStatusDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_UPDATE_JOINING_STATUS,
      {
        ...joineeStatusDto,
      }
    );
  };

  public sendWelcomeMail = async (joineeStatusDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_WELCOME_MAIL,
      {
        ...joineeStatusDto,
      }
    );
  };

  public sendNextStepMail = async (joineeStatusDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_NEXT_STEPS_MAIL,
      {
        ...joineeStatusDto,
      }
    );
  };

  public revokeNotJoinedAccess = async (joineeStatusDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_REVOKE_ACCESS_NOT_JOINED,
      {
        ...joineeStatusDto,
      }
    );
  };

  public saveNewJoineeInfo = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_SAVE_FORM,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public giveJoineeAccess = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<JoineeAccessInfo>>(
      RoutesEnum.ONBOARDING_JOINERS_ACCESS,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public inviteJoineeZoho = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<JoineeAccessInfo>>(
      RoutesEnum.ONBOARDING_JOINER_INVITE_ZOHO,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public inviteJoineeJira = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<JoineeAccessInfo>>(
      RoutesEnum.ONBOARDING_JOINER_INVITE_JIRA,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public saveNewSource = async (sourceDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_SAVE_SOURCE,
      {
        ...sourceDataDto,
      }
    );
  };

  public saveNewDesignation = async (sourceDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_SAVE_DESIGNATION,
      {
        ...sourceDataDto,
      }
    );
  };

  public revokeEmployeeAccess = async (employeeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<EmployeeInfo>>(
      RoutesEnum.OFFBOARDING_REVOKE_EMPLOYEE_ACCESS,
      {
        ...employeeInfoDataDto,
      }
    );
  };


  public getProfileImageUrl = async (profile_image: {}) => {
    return axiosClient.post<IApiBaseResponse<profileImage>>(
      RoutesEnum.ONBOARDING_JOINER_GET_PROFILE_IMAGE,
      {
        ...profile_image,
      }
    );
  };

public processProfilePicByGcloud = async (file: FormData) => {
  return axios.post('https://us-central1-da-housekeeping.cloudfunctions.net/process_image', file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob',
  });    

};


  public UploadImageToCloud = async (file: FormData) => {
      return axiosClient.post(RoutesEnum.ONBOARDING_JOINER_UPLOAD_IMAGE, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });    
    
  };


  public postOnboardingDocuments = async (uploadBiometricData: {}, date_for: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.UPLOAD_ONBOARDING_DOCUMENTS,
      uploadBiometricData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: date_for,
      }
    );
  };

  public getCandidateDetails = async (params: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(RoutesEnum.GET_CANDIDATE_DETAILS + params['token']);
  }

  public getUploadedFilesAndFolderNames = async (params: {}) => {
    console.log(params);
    
    return (
      axiosClient.get<IApiBaseResponse<any>>(
        RoutesEnum.GET_FOLDER_NAMES_AND_UPLOADED_FILES, 
        {
          params
        }
      )
    )
  }

  public deleteFileFromGdrive = async (data: {}) => {
    console.log(data);
    
    return (
      axiosClient.post<IApiBaseResponse<any>>(
        RoutesEnum.DELETE_ONBOARDING_DOCUMENTS, data
      )
    )
  }
  public resendUploadOnboardingDocumentEmail = async (data: any) => {
    
    return (
      axiosClient.get<IApiBaseResponse<any>>(
        RoutesEnum.RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL + data.joineeId
      )
    )
  }
}
