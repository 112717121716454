import React from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner'
import NoDataFound from 'views/NoDataFound'

interface tableDataInterface { name: string, timesheet: string[] }
interface ITimesheetTableInterface {
    isTableLoading: boolean,
    tableHeaders: number[],
    tableData: tableDataInterface[]
}

const TimesheetTable: React.FC<ITimesheetTableInterface> = ({ isTableLoading, tableHeaders, tableData }) => {
    return (
        <LoadingSpinner isLoading={isTableLoading}>
            <div className="customTbl userManagementTbl scroll-y tblStickyTHead timeDelayReport">
                <table className="main-table">
                    <thead>
                        <tr>
                            <th className='colLeftSticky text-left' style={{ left: "0", minWidth: "180px", }}>Name</th>
                            {
                                tableHeaders.map((curr_day) => {
                                    return (
                                        <th className='text-center'
                                            style={{ minWidth: "50px", }}
                                            key={curr_day * 5}>
                                            {curr_day}
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {

                            tableData?.map((emp_timesheet: any) => {

                                return (
                                    <tr key={emp_timesheet.name}>
                                        <td className='colLeftSticky text-left' style={{ left: "0" }}>{emp_timesheet.name}</td>
                                        {
                                            emp_timesheet?.timesheet?.map((dayData: any, index: number) => {
                                                const isGreaterThanSeven = dayData > 7;

                                                let dayDataStr = ''

                                                if(dayData === 0){
                                                    dayData = dayDataStr;
                                                }

                                                return (
                                                    <td className={`text-center ${isGreaterThanSeven ? 'highlighted highlighted-pink' : ''}`} key={index} style={{ minWidth: "50px" }}><span>{dayData}</span></td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {(!tableData || tableData.length === 0) && <NoDataFound />}
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </LoadingSpinner>
    )
}

export default TimesheetTable;
