import {Fragment} from "react";
import { Route,RouteProps } from "react-router";
import { Switch } from "react-router-dom";
import { AuthenticateRoute, AnonymousRoute } from "./RouteConfig";
import { routes } from "./OtherRoute";
import { NotFound} from "./NotFound";

const Routes: React.FC<RouteProps> = () => {

  return (
    <Switch>
        {
          routes?.map((route) => {
            
            return (
              route.auth ? (
                  (<AuthenticateRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    access={route.access}
                  />)
              ) : (
                <AnonymousRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  redirectTo={route.redirectTo}
                />
              )
            )
          }
          )}
        <Route component={NotFound}></Route>
      </Switch>
  );
};

export default Routes;
