
import { ServerError } from "layout/ServerError";
import {
  GoogleSignIn,
  OffboardingPage, 
  OnboardingPage, 
  TimesheetDelayReport, 
  RecruitmentBoard, 
  DelayAttendanceReport, 
  RegularizationList, 
  UploadBiometricDocuments,
  SpecificRegularizeIdDetails,
  LeaveReconcilliationMaster,
  TotalHoursReport,
  UploadLeaveDocument,
  LeaveReport,
  TeamMembers,
  HistoryRequestesList,
  SpecificWfhIdDetails,
  WfhList,
  UploadDocument,
  UploadOnboardingDocument
} from "views";
import { LeftNavRoutesEnum } from "models/enums/leftNavRoutes";

const routes = [
  {
    auth: false,
    path: "/login",
    exact: true,
    component: GoogleSignIn,
    redirectTo: '/onboarding'
  },
  {
    auth: false,
    path: "/",
    exact: true,
    component: GoogleSignIn,
    redirectTo: '/onboarding'
  },
  {
    path: "/server-error",
    exact: true,
    component: ServerError
  },
  {
    auth: true,
    path: "/onboarding",
    exact: true,
    component: OnboardingPage,
    access: [1, 2]
  },
  {
    auth: true,
    path: "/offboarding",
    exact: true,
    component: OffboardingPage,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.TIMESHEET_DELAY_REPORT,
    exact: true,
    component: TimesheetDelayReport,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.RECRUITMENT_BOARD,
    exact: true,
    component: RecruitmentBoard,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.DAILY_ATTENDANCE_BOARD,
    exact: true,
    component: DelayAttendanceReport,
    access: [1, 2, 3]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.REGULARIZATION_LIST,
    exact: true,
    component: RegularizationList,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT,
    exact: true,
    component: UploadBiometricDocuments,
    access: [1, 2]
  },
  {
    auth: true,
    path: "/regularize/details/:id",
    exact: true,
    component: SpecificRegularizeIdDetails,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.TOTAL_HOURS_REPORT,
    exact: true,
    component: TotalHoursReport,
    access : [1, 2],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.LEAVE_RECONCILIATION_MASTER,
    exact: true,
    component: LeaveReconcilliationMaster,
    access : [1, 2],
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.LEAVE_RECONCILIATION,
    exact: true,
    component: LeaveReport,
    access : [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.UPLOAD_LEAVE_DOCUMENT,
    exact: true,
    component: UploadLeaveDocument,
    access : [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.TEAM_MEMBERS,
    exact: true,
    component: TeamMembers,
    access: [1, 2],
  },
  {
    auth: true,
    path: "/wfh/details/:id",
    exact: true,
    component: SpecificWfhIdDetails,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.HISTORY_WFH_REQUESTS,
    exact: true,
    component: HistoryRequestesList,
    access: [1, 2, 3]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.WFH_LIST,
    exact: true,
    component: WfhList,
    access: [1, 2]
  },
  {
    auth: true,
    path: LeftNavRoutesEnum.UPLOAD_DOCUMENTS,
    exact: true,
    component: UploadDocument,
    access: [1, 2]
  },
  {
    auth: false,
    path: "/upload-onboarding-documents/:id",
    exact: true,
    component: UploadOnboardingDocument,
    access: [1, 2]
  },
];

export { routes };
