enum LeftNavRoutesEnum  {
  ONBOARDING = "/onboarding",
  OFFBOARDING = "/offboarding",
  UPLOAD_DOCUMENTS = "/upload-documents",
  TEAM_MEMBERS = "/team-members",

  REPORT = "/report",
  RECRUITMENT_BOARD = "/report/recruitment-board",
  TIMESHEET_DELAY_REPORT = "/report/timesheet-delay",
  TOTAL_HOURS_REPORT = "/report/total-hours",

  BIOMETRIC = "/biometric",
  DAILY_ATTENDANCE_BOARD = "/biometric/daily-attendance-board",
  REGULARIZATION_LIST = "/biometric/regularization-list",
  UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT = "/biometric/upload-documents",

  LEAVE_TRACKER = '/leave-reconcilliation',
  LEAVE_RECONCILIATION_MASTER = "/leave-reconcilliation/master-sheet",
  LEAVE_RECONCILIATION = "/leave-reconcilliation/leave-data",
  UPLOAD_LEAVE_DOCUMENT = "/leave-reconcilliation/upload-document",

  WORK_FROM_HOME = "/work-from-home",
  HISTORY_WFH_REQUESTS = "/work-from-home/history-wfh-requests",
  WFH_LIST = "/work-from-home/list",
}


enum LeftNavMenuKeysEnum {
  ONBOARDING = "/onboarding",
  OFFBOARDING = "/offboarding",
  UPLOAD_DOCUMENTS = "/upload-documents",
  TEAM_MEMBERS = "/team-members",

  REPORT = "/report",
  RECRUITMENT_BOARD = "/report/recruitment-board",
  TIMESHEET_DELAY_REPORT = "/report/timesheet-delay",
  TOTAL_HOURS_REPORT = "/report/total-hours",

  BIOMETRIC = "/biometric",
  DAILY_ATTENDANCE_BOARD = "/biometric/daily-attendance-board",
  REGULARIZATION_LIST = "/biometric/regularization-list",
  UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT = "/biometric/upload-documents",

  LEAVE_TRACKER = '/leave-reconcilliation',
  LEAVE_RECONCILIATION_MASTER = "/leave-reconcilliation/master-sheet",
  LEAVE_RECONCILIATION = "/leave-reconcilliation/leave-report",
  UPLOAD_LEAVE_DOCUMENT = "/upload-leave-document",

  WORK_FROM_HOME = "/work-from-home",
  HISTORY_WFH_REQUESTS = "/work-from-home/history-wfh-requests",
  WFH_LIST = "/work-from-home/list",
}

export { LeftNavRoutesEnum, LeftNavMenuKeysEnum };