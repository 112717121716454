import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Upload } from 'antd';
import React, { useState } from 'react';
const { Dragger } = Upload;

interface IUploadComponent {
    isDisabled: () => boolean;
    handleUpload: (fileList: UploadFile[], data: string) => Promise<void>;
    acceptedTypes: string;
    multiple?: boolean
    data?: number
}
const UploadComponent: React.FC<IUploadComponent> = ({isDisabled, handleUpload, acceptedTypes, multiple, data}) => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const onUpload = () => {
        handleUpload(fileList, String(data) || '')
        setFileList([])
    }
    

    const props: UploadProps = {
        name: 'file',
        multiple: multiple || false,
        fileList,
        accept: acceptedTypes,
        maxCount: multiple? 10 : 1,

        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },

        beforeUpload: (file, fileList) => {
            console.log(file);
            console.log(fileList);
            let updatedFile = [file];

            if(multiple){
                updatedFile = fileList
            }
            
            setFileList(updatedFile);
            return false;
        }
    };



    return (
        <form encType="multipart/form-data">
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <Button
                type="primary"
                onClick={onUpload}
                disabled={fileList.length === 0 || isDisabled()}
                style={{ marginTop: 16 }}
            >
                Upload
            </Button>
        </form>
    )
}
export default UploadComponent;