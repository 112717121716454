import { RoutesEnum } from "models/enums";
import {
  JoinersListInfo,
  EmployeeRevokeInfo
} from "models/interfaces";
import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";

export class JoinersListOffboardingService {
  public getExitList = async (joinersListQueryDto: {}) => {
    return axiosClient.get<IApiBaseResponse<JoinersListInfo>>(
      RoutesEnum.OFFBOARDING_EMPLOYEES_LIST,
      {
        params: joinersListQueryDto,
      }
    );
  };

  public getEmployeeIDExistance = async (employeeIDDto: {}) => {
    return axiosClient.get<IApiBaseResponse<any>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_ID_CHECK,
      {
        params: employeeIDDto,
      }
    );
  };

  public deleteEmployeeFromExitQueue = async (employeeDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_DELETE,
      {
        ...employeeDataDto,
      }
    );
  };

  public updateExitStatus = async (joineeStatusDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.ONBOARDING_JOINERS_UPDATE_JOINING_STATUS,
      {
        ...joineeStatusDto,
      }
    );
  };

  public saveExitMemberInfo = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<any>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_SAVE_FORM,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public revokeAccess = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_REVOKE,
      {
        ...joineeInfoDataDto,
      }
    );
  };
  
  public deleteZoho = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_DELETE_ZOHO,
      {
        ...joineeInfoDataDto,
      }
    );
  };
  
  public deleteJira = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_DELETE_JIRA,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public deleteWorkspaceUser = async (joineeInfoDataDto: {}) => {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_DELETE_WORKSPACE_USER,
      {
        ...joineeInfoDataDto,
      }
    );

  };
  public completeOffboarding = async (joineeInfoDataDto: {})=> {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_COMPLETE,
      {
        ...joineeInfoDataDto,
      }
    );
  };

  public processOffboarding = async (joineeInfoDataDto: {})=> {
    return axiosClient.post<IApiBaseResponse<EmployeeRevokeInfo>>(
      RoutesEnum.OFFBOARDING_EMPLOYEE_PROCESS,
      {
        ...joineeInfoDataDto,
      }
    );
  };
}
