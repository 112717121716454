
import { useCallback, useMemo } from "react";
import { initialMenu } from "../layout/LayoutUtils";
import { useTokenID } from "utils/auth/google.security";

const UseLeftPanelLinksFinder = () => {
    const userInfo = useTokenID();
    let role_id = userInfo?.sub?.role_id || 3;


        const findLeftPanel = useCallback((menuArray: any, role_id: number) => {
            let leftPanel: any [] = [];
            menuArray.forEach((curr_elem: any) => {
            
            if(curr_elem.childrens){
                const childrens: any [] = findLeftPanel(curr_elem.childrens, role_id);
                
                if(childrens?.length > 0)
                leftPanel = [...leftPanel, {...curr_elem, childrens }];
            }
            else {
                if (curr_elem?.accassableTo?.includes(role_id)){
                leftPanel = [...leftPanel, curr_elem];
                }
            }
            });
            return leftPanel;
        }, []);


    const letSideBarLinks = useMemo(() => findLeftPanel(initialMenu, role_id), [role_id, findLeftPanel])
    

    return { letSideBarLinks }
}

export default UseLeftPanelLinksFinder;
