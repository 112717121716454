import { IApiBaseResponse } from "models/interfaces/Iglobal";
import axiosClient from "sharedServices/api/axios.services";
import { RoutesEnum } from "models/enums";

export class TeamMembersService{
    public getTeamMembers = async () => {
      return axiosClient.get<IApiBaseResponse<any>>(
        RoutesEnum.GET_TEAM_MEMBERS
    );
    }
};