import { Collapse, Layout, UploadFile } from 'antd';
import { useRbac } from 'features/rbac/rbac';
import { usePageTitle } from 'hooks';
import { useApiServices } from 'hooks/useApiServices';
import { useEffect, useMemo, useState } from 'react';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import UploadComponent from 'views/Biometric/UploadComponent';
import { Constants } from 'views/constants';
const { Panel } = Collapse;


const UploadDocument = () => {

  usePageTitle(Constants.UPLOAD_DOCUMENTS_TITLE);
  const { joinee_id } = useRbac().userDetailsInfo.sub;
  const [openedFolders, setOpenedFolders] = useState<string[]>([]);

  const { updateData, isLoading, fetchData, data } = useApiServices();
  const pageData: { documents: any[], folders: string[] } = useMemo(() => {
    setOpenedFolders(data?.folders?.map((folder: any, index: number) => index+1))
    return { documents: data?.documents || {}, folders: data?.folders || [] }
  }, [data])

  const isDisabled = () => isLoading

  const handleUpload = async (fileList: UploadFile[], data: string) => {
    const formData = new FormData();

    formData.append('folder_id', data)
    formData.append('joinee_id', joinee_id)

    if (fileList) {
      fileList.forEach((file) => {
        formData.append('files', file as unknown as Blob)
      })
    }

    const response = await updateData('UPLOAD_ONBOARDING_DOCUMENTS', formData);
    
    if (response) {
      await fetchData('GET_FOLDER_NAMES_AND_UPLOADED_FILES', { joinee_id })
    }
    
  };
  
  const handleDeleteFile = async (fileName: string, folderId: number, joineeId: number) => {
    await updateData('DELETE_ONBOARDING_DOCUMENTS', {file_name: fileName, folder_id: folderId, joinee_id: joineeId});
    await fetchData('GET_FOLDER_NAMES_AND_UPLOADED_FILES', { joinee_id });
  }


  useEffect(() => {
    fetchData('GET_FOLDER_NAMES_AND_UPLOADED_FILES', { joinee_id });
  }, [joinee_id, fetchData])


  return (
    <Layout className="mainInnerLayout uploadDocument">
      <div className="innerHeader headerSingle">
        <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
          <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
            <h3>{Constants.UPLOAD_DOCUMENTS}</h3>
          </div>
        </div>
      </div>
      <div>
        <LoadingSpinner isLoading={isLoading}>
            <Collapse
              className='upload-file-collapse'
              activeKey={openedFolders}
              onChange={(keyArray) => {
                console.log(keyArray);
                
                setOpenedFolders(typeof (keyArray) == 'string'? [keyArray] : keyArray);
              }} >
              {
                pageData?.folders?.map((data, index) => (
                  <Panel header={data} key={index + 1} style={{ fontSize: '1.2rem' }}>

                    {
                        <ul className='uploadedFileList'>
                          {
                            pageData?.documents[index + 1]?.map((curr_file: any) => {
                              return (
                                <li key={curr_file.id}>
                                  <span>{curr_file.file_name}</span>
                                  <i className='da icon-tick-circle icon-tick-cross-circle' onClick={() => handleDeleteFile(curr_file.file_name, index+1, joinee_id)}></i>
                                </li>
                              )
                            })
                          }
                        </ul>
                    }
                    <UploadComponent
                      isDisabled={isDisabled}
                      handleUpload={handleUpload}
                      acceptedTypes='.pdf, .xlxs, .xls, .csv, .png'
                      multiple={true}
                      data={index + 1}
                    />

                  </Panel>
                ))
              }
            </Collapse>
        </LoadingSpinner>
      </div>
    </Layout>
  )
}

export default UploadDocument;
