import React, { useState, useEffect } from 'react';
import { auth } from 'utils/auth/google.security';
import { getEncodedData } from 'utils/commonFunction';
import { useHistory } from 'react-router';
import { ILeaveReconciliationColumnSelect, ILeaveReconciliationVersionSelect, LeaveData, versionData, ILeaveReconciliationFilterParams } from 'models/interfaces';
import LeaveReportTable from './LeaveReportTable';
import { Constants } from '../constants';
import moment from 'moment';
import { Button, Input, Layout } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { usePageTitle } from 'hooks';
import { LeaveReconcilliationService } from 'services/leaveReconcilliation/leaveReconcilliationMaster.service';
import ExcelJS from 'exceljs';
import LeaveReconciliationDrawer from './LeaveReconciliationDrawer';


const getDefaultHraders = () => {
    let headers = ['name', 'opening_balance'];
    for(let i=0; i<12; i++){
        headers.push(moment().month(i).format('MMM').toLocaleLowerCase())
    }
    headers.push('booked_zoho_leaves')
    headers.push('booked_jira_leaves')
    headers.push('closing_balance')
    headers.push('updated_on')
    return headers;
}


const LeaveReport = () => {

    usePageTitle(Constants.LEAVE_REPORT_TITLE);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [tableHeaders, setTableHeaders] = useState<string[]>(getDefaultHraders())
    const [apiData, setApiData] = useState<{leaveData: LeaveData[], versionData: versionData[]}>({leaveData: [], versionData: []});
    const [tableData, setTableData] = useState<any []>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);


    const [dropdownOptions, setDropdownOptions] = useState<{columns: ILeaveReconciliationColumnSelect [], versions: ILeaveReconciliationVersionSelect []}>({columns: [], versions: []});
    const [tempFilterParams, setTempFilterParams] = useState<ILeaveReconciliationFilterParams>({searchText: '', columns: [], version: null});
    const [filterParams, setFilterParams] = useState<ILeaveReconciliationFilterParams>({searchText: '', columns: [], version: null});
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();


    const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("employee_code");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };
    

    // RESTRACTURING THE TABLE DATAS
    const buildingTableDatas = (leaveData: LeaveData[], versionData: versionData[]) => {
        const defaultTableHeaders = getDefaultHraders();
        
        const updatedTableData = leaveData.map((emp_leave_data: any) => {
            let weekDaysData =  {};

            defaultTableHeaders.forEach((curr_header) => {
                if(curr_header === 'updated_on'){  
                    const date = moment(emp_leave_data['created_on']);
                    weekDaysData[curr_header] = `${date.format('MMM')} ${date.date()}, ${date.format('YYYY')}`;
                }
                else
                    weekDaysData[curr_header] = emp_leave_data[curr_header];
            })
            return weekDaysData;
        });

        // getting version dropdown options
        const dropdownVersionArray: ILeaveReconciliationVersionSelect[] = versionData.map(({version, created_on}: versionData) => {
            const date = moment(created_on)
            return {
                label: `V${version} - ${date.format('MMM')} ${date.date()}, ${date.year()}`,
                value: version
            }
        });


        let dropDownColumnArray: ILeaveReconciliationColumnSelect[] = []

        for(let i=0; i<12; i++){
            dropDownColumnArray.push({
                label: moment().month(i).format('MMM'),
                value: moment().month(i).format('MMM').toLowerCase()
            });
        }

        setDropdownOptions({columns: dropDownColumnArray, versions: dropdownVersionArray})
        setTableData(updatedTableData);
        applyFilter(updatedTableData);
        
    }


    // API CALL
    const getLeaveData = async (params?: any) => {
        setIsTableLoading(true);
        try {
            const response: any = await new LeaveReconcilliationService().getLeaveData({...params});
            
            setApiData({leaveData: response?.data?.leaveData, versionData: response?.data?.versionData});
            setError(null);
        } catch (error: any) {
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: getLeaveData,
            });
        }
        finally {
            setIsTableLoading(false);
        }
    };


    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (searchTimeOut) {
          clearTimeout(searchTimeOut);
        }
        const value = e.target.value;
        const ele: any = e.nativeEvent;
        const key: any = ele.data;
        const inputType = ele.inputType;
        
        if (
          key !== null &&
          inputType !== "deleteContentBackward" &&
          (value === "" || value?.length < 3)
        ) {
            setTempFilterParams((prev: any) => {
                return {
                ...prev,
                searchText: "",
                };
            });
            setFilterParams((prev: any) => {
                return {
                ...prev,
                searchText: "",
                };
            });
            return;
        }
        setSearchTimeOut(
          setTimeout(
            () => {
                setTempFilterParams((prev: any) => {
                  return {
                    ...prev,
                    searchText: value,
                  };
                })
                setFilterParams((prev: any) => {
                  return {
                    ...prev,
                    searchText: value,
                  };
                })

            },
            1200
          )
        );
      }
      
      
      const applyFilter = (tableData: any []) => {
        let filteredTableData: any[]  = []
        let updatedTableHeaders = tableHeaders
        
        if(filterParams.searchText !== null && filterParams.searchText !== ''){
            filteredTableData = tableData.filter((curr_row: any) =>  (
                curr_row['name']?.toLocaleLowerCase()?.includes(filterParams.searchText.toLocaleLowerCase())
            ))
        }
        else{
            filteredTableData = tableData;
        }

        if(filterParams?.columns?.length !== 0){
            updatedTableHeaders = ['name', 'opening_balance', ...filterParams?.columns, 'booked_zoho_leaves', 'booked_jira_leaves', 'closing_balance', 'updated_on'];
        }
        else{
            updatedTableHeaders = getDefaultHraders();
        }

        
        
        filteredTableData =  filteredTableData.map((emp_leave_data: any) => {
            let weekDaysData =  {};
            
            updatedTableHeaders.forEach((curr_header) => {
                weekDaysData[curr_header] = emp_leave_data[curr_header];
            })
            return weekDaysData;
        });

        setTableHeaders(updatedTableHeaders)
        setFilteredData(filteredTableData);
    }

    
    const exportToExcelFile = (data: any[]) => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0'); // Get day with leading zero if needed
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get month with leading zero if needed (Note: January is 0)
        const year = currentDate.getFullYear(); // Get full year (4 digits)
        const formattedDate = `${day}-${month}-${year}`;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Recruitment Report");
        
        const headerColumn: Partial<ExcelJS.Column>[] | { header: any; key: any; width: number; }[] = [];
        const headerColumns: string [] = [...tableHeaders]
        const headers = tableHeaders.map(key => { 
            return key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
        });
        
        
        headers.forEach((header: string) => {
            let obj = {
                header: header,
                key: header,
                width: header === 'Name'? 25 :
                (header == 'Booked Zoho Leaves' ||  header == 'Booked Jira Leaves' ||  header == 'Closing Balance' || header == 'Opening Balance' || header == 'Updated On')? 20 : 8
            }
            headerColumn.push(obj)
        });
        
        
        sheet.columns = headerColumn;
        
        data?.forEach((curr_data: any) => {
            const formattedItem = {};
            headerColumns.forEach((key) => {
                const header = key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()); // Key that matches the headers
                formattedItem[header] = curr_data[key]; // Value from the original item
            });
            const row = sheet.addRow(formattedItem);
            row.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
        })
        
        sheet.getRow(1).font = { bold: true };
        sheet.getRow(1).alignment = { vertical: 'top', horizontal: 'left' };

        sheet.eachRow({ includeEmpty: true }, (row) => {
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              if (typeof cell.value === "number") {
                cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'right' };
              }
            });
          });
        
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vvnd.openxmlformats-officedocument.spreadsheet.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `leave_report_${formattedDate}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        })
    }
    

    
    
    
    // REBUILD THE TABLE DATAS IF THE TABLE HEADER OR DATAS CHANGED
    useEffect(() => {
        buildingTableDatas(apiData.leaveData, apiData.versionData);
    }, [apiData]);


    // FETCH THE TIMESHEET DELAY DATA OF THE SELECTED MONTH OR YEAR CHANGED
    useEffect(() => {
        getLeaveData({version: filterParams.version});
    }, [filterParams.version]);


    useEffect(() => {
        if (!isTableLoading) applyFilter(tableData)
    }, [filterParams.columns, filterParams.searchText]);
    
    
    return (
        <>
            {
                error ? (
                    <React.Fragment>
                        <h2>{error.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{error.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (

                    <Layout className="mainInnerLayout leaveReconciliaton">
                        <div className="innerHeader headerSingle">
                            <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                                    <h3 style={{width: '50%'}}>{Constants.LEAVE_REPORT}</h3>
                                    <Input
                                    placeholder={Constants.SEARCH_LABEL_LEAVE_RECONCILIATION}
                                    disabled={isTableLoading}
                                    allowClear
                                    onChange={handleChange}
                                    />
                                </div>

                                <div className='flx ant-col-xl-8' style={{justifyContent: 'flex-end'}}>
                                    <div className="innerHeaderRight flx alignItemsFlexEnd">
                                        <div >
                                            <Button 
                                            className="addUserBtn mr-10 custom-export" 
                                            disabled={isTableLoading}
                                            icon={
                                                <DownloadOutlined style={{ color: 'white', 'paddingTop': '3px' }} 
                                                onPointerOverCapture={undefined} 
                                                onPointerMoveCapture={undefined} 
                                                />
                                            } 
                                            onClick={() => exportToExcelFile(filteredData)}>
                                                Export to XLSX
                                            </Button>

                                        </div>
                                        <LeaveReconciliationDrawer
                                        disabled={isTableLoading}
                                        open={open}
                                        setOpen={setOpen}
                                        dropdownOptions={dropdownOptions}
                                        filterParams={filterParams}
                                        setFilterParams={setFilterParams}
                                        tempFilterParams={tempFilterParams}
                                        setTempFilterParams={setTempFilterParams}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <LeaveReportTable
                            isTableLoading={isTableLoading}
                            tableHeaders={tableHeaders}
                            tableData={filteredData}
                            setTableData={setTableData}
                            />
                        </div>
                    </Layout>
                )
            }
        </>
    );
};

export default LeaveReport; 