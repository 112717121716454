import { Button, Layout } from 'antd';
import { useRbac } from 'features/rbac/rbac';
import moment from 'moment';
import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import RejectDrawer from 'utils/regularizeDrawer/RejectDrawer';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { Constants } from 'views/constants';
import { usePageTitle } from 'hooks';
import { useApiServices } from 'hooks/useApiServices';
import { useParams } from 'react-router';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import EditAndApproveDrawer from 'utils/regularizeDrawer/EditAndApproveDrawer';
import { getWeekDays } from 'utils/commonFunction';


interface ISpecificWfhTableData {
    id: number;
    Name: string;
    Employee_Code: string;
    From_Date: string;
    To_Date: string ;
    Days: number [] ;
    Reason: string;
    Document: any;
    isDeleted: boolean
    isApproved: boolean | null
}

const SpecificWfhIdDetails = () => {

    usePageTitle(Constants.NEW_WORK_FROM_HOME_REQUEST_TITLE);

    // reject drawer states
    const [rejectReason, setRejectReason] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [editAndApproveDrawerOpened, setEditAndApproveDrawerOpened] = useState<boolean>(false);
    const [wfhData, setWfhData] = useState<any>({});
    const [wfhDataDefault, setWfhDataDefault] = useState<any>({});

    const { id } = useParams<{ id: string }>();
    const header = useMemo(() => ['Name', 'Employee_Code', 'From_Date', 'To_Date', 'Days', 'Reason', 'Document'], []);
    const { name } = useRbac().userDetailsInfo.sub;
    const { isLoading, data, isError, fetchData, updateData } = useApiServices();
    const [tableData, setTableData] = useState<ISpecificWfhTableData | null>(null);
    const weekDaysToShow = useMemo(() => getWeekDays(wfhData?.start_date, wfhData?.end_date), [wfhData])


    const structureData = useCallback((data: any) => {

        if (!data?.wfh_requests || Object.keys(data['wfh_requests']).length === 0) return null;

        const wfhRequests = data['wfh_requests']
        let acc: ISpecificWfhTableData = { 
            id: wfhRequests.id,
            Name: '',
            Employee_Code: '',
            From_Date: '',
            To_Date: '',
            Days: [],
            Reason: '',
            Document: null,
            isDeleted: wfhRequests['is_deleted'] || false,
            isApproved: wfhRequests['is_approved']
        };
        let wfhData: any = { id: wfhRequests.id, edit_reason: '' }

        header?.forEach((currHeader: string) => {
            const currHeaderLower = currHeader.toLowerCase();

            if (currHeaderLower === 'days') {
                acc[currHeader] = wfhRequests['wfh_days']?.reduce((accu: string, curr: number) => {
                    const currWeekDay = moment().startOf('year').day(curr).format('dddd');
                    return accu === '' ? currWeekDay : `${accu}, ${currWeekDay}`;
                }, '');

                wfhData['days'] = wfhRequests['wfh_days']
            }
            else if (currHeaderLower === 'from_date' || currHeaderLower === 'to_date') {
                const changedHeader = currHeaderLower === 'from_date' ? 'start_date' : 'end_date';
                acc[currHeader] = wfhRequests[currHeaderLower] = moment(wfhRequests[currHeaderLower]).format('MMMM D, YYYY');
                wfhData[changedHeader] = moment(wfhRequests[currHeaderLower])
            }
            else if (currHeaderLower === 'document' && !wfhRequests[currHeader]) {
                acc[currHeader] = null
                wfhData[currHeaderLower] = null
            }
            else {
                acc[currHeader] = wfhRequests[currHeaderLower];
                wfhData[currHeaderLower] = wfhRequests[currHeaderLower];
            }
        });


        setWfhDataDefault(wfhData);
        return acc
    }, [header]);



    // Approve or reject work from home
    const handleApproveReject = async (task: string, selectedItems: number[], selectedObj?: any) => {

        const obj = {
            id: selectedItems,
            is_approved: task === 'approved' || false,
            updated_by: name
        }

        if (selectedObj?.rejectReason)
            obj['reject_reason'] = selectedObj?.rejectReason;

        if (selectedObj?.start_date) {
            obj['from_date'] = selectedObj?.start_date?.format('YYYY-MM-DD');
            obj['to_date'] = selectedObj?.end_date?.format('YYYY-MM-DD');
            obj['edit_reason'] = selectedObj?.edit_reason;
            obj['wfh_days'] = selectedObj?.days;
            cancelEditAndApprove();
        }

        if (task !== 'approved') {
            cancelReject();
        }
        
        await updateData('UPDATE_WFH_REQUEST_STATUS', obj);
        setTableData(null)

        await fetchData('GET_WFH_REQUEST_LIST', { id })
    }


    const cancelReject = () => {
        setRejectReason('');
        setOpen(false)
    }


    const cancelEditAndApprove = () => {
        setEditAndApproveDrawerOpened(false);
        setWfhData(wfhDataDefault);
    }


    const handleClickEditAndApprove = () => {
        setEditAndApproveDrawerOpened(true);
        setWfhData(wfhDataDefault);
    }


    function handleChange(e: ChangeEvent<HTMLTextAreaElement> | undefined) {
        if (e && e.target) setRejectReason(e?.target.value)
    }


    const cancleReject = () => {
        setOpen(false);
        setRejectReason('');
    }

    useEffect(() => {
        const tableData = structureData(data);
        setTableData(tableData)
    }, [data, structureData]);

    useEffect(() => {
        fetchData('GET_WFH_REQUEST_LIST', { id })
    }, [fetchData, id]);

    return (
        <Layout className="mainInnerLayout full-screen specificWfhDetails">
            {isError ? (
                <React.Fragment>
                    <h2>{isError.code} Error</h2>
                    <div className='errorContent'>
                        <div className='errorContentInner'>
                            <span className='error-img'></span>
                            <div>
                                <h3>{isError.message}</h3>
                                <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) :
                (
                    <LoadingSpinner isLoading={isLoading}>
                    {
                        tableData && Object?.keys(tableData)?.length > 0 ?
                        <>
                        {
                            tableData.isDeleted && 
                            (<h2>this request has been removed.</h2>)
                        }
                        {
                            !tableData.isDeleted && tableData.isApproved && 
                            (<h2>this request has been accepted.</h2>)
                        }
                        {
                            !tableData.isDeleted && tableData.isApproved === false && 
                            (<h2>this request has been rejected.</h2>)
                        }
                        {
                            !tableData.isDeleted && tableData.isApproved === null && 
                            (
                                (<div>
                                    <div className="innerHeader headerSingle">
                                        <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                            <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                                                <h3 style={{ marginBottom: '10px'}}>{Constants.SPECIFIC_WFH_REQUEST}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='listTableWrap'>
                                        <div className='customTbl listTable'>
                                            <table>
                                                <tbody>
                                                    {header.map((currHeader) => {
                                                        return (
                                                            <Fragment key={currHeader}>
                                                                {
                                                                    ((currHeader !== 'Document') || (currHeader === 'Document' && tableData['document'])) &&
                                                                    (
                                                                        <tr key={currHeader}>
                                                                            <td>{currHeader.split('_').join(' ')}:</td>
                                                                            <td>{tableData[currHeader]}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='mt-10' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '.5rem', marginTop: '10px' }}>
                                        <Button
                                            className='regularization-button'
                                            id='regularization-approve'
                                            onClick={() => handleApproveReject('approved', [tableData?.id], tableData)}
                                            disabled={isLoading}
                                        >
                                            <CheckOutlined style={{ color: 'white' }} />
                                        </Button>
                                        <Button
                                            className='regularization-button'
                                            id='regularization-reject'
                                            onClick={() => setOpen(true)}
                                            disabled={isLoading}
                                        >
                                            <CloseOutlined style={{ color: 'white' }} />
                                        </Button>
                                        <Button onClick={handleClickEditAndApprove} style={{border: 'none', outline: 'none', background: 'transparent', padding: '0', margin: '0'}}>
                                            <i className='da icon-edit pointer'></i>
                                        </Button>
                                    </div>
                                    <EditAndApproveDrawer
                                        cancel={cancelEditAndApprove}
                                        wfhData={wfhData}
                                        setWfhData={setWfhData}
                                        isWfhOpen={editAndApproveDrawerOpened}
                                        setIsWfhOpen={setEditAndApproveDrawerOpened}
                                        submitWfhData={handleApproveReject}
                                        weekDaysToShow={weekDaysToShow}
                                    />
                                    {open && (
                                        <RejectDrawer
                                            open={open}
                                            selectedElem={[tableData?.id]}
                                            setOpen={setOpen}
                                            cancleReject={cancleReject}
                                            submitRejectData={handleApproveReject}
                                            handleChange={handleChange}
                                            rejectData={rejectReason}
                                            setRejectData={setRejectReason}
                                            title='work from home'
                                        />
                                    )}
                                </div>))
                        }
                        </>
                           
                        :
                        !isLoading?
                        <h2>invalid link</h2>
                        :
                        <></>
                    }
                    </LoadingSpinner>
                )
            }
        </Layout>
    );
}

export default SpecificWfhIdDetails