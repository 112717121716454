import React from "react";
import { Select } from "antd";
import { ILeaveReconciliationColumnSelect, ILeaveReconciliationFilterParams } from "models/interfaces";

interface i {
    tempFilterParams: ILeaveReconciliationFilterParams, 
    dropdownOptions: ILeaveReconciliationColumnSelect [], 
    keyName: string, 
    onChange: (key: string, value: string[]) => void
}
const LeaveReconciliationMultiSelect: React.FC<i> = ({ tempFilterParams, dropdownOptions, keyName, onChange }) => {
  const handleChange = (value: string[]) => {
    onChange(keyName, value)
  };
  

  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{`Select ${keyName}`}</label>
        <Select
          mode="multiple"
          showSearch
          showArrow
          placeholder="Please select"
          value={tempFilterParams[keyName.toLowerCase()]}
          onChange={handleChange}
          className="width-100"
          options={dropdownOptions}
          allowClear
        />
      </div>
    </React.Fragment>
  );
};

export default LeaveReconciliationMultiSelect;
