import { Button, message } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RegularizationRequestService } from "services"
import { auth } from 'utils/auth/google.security';
import { getEncodedData } from 'utils/commonFunction';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { useRbac } from 'features/rbac/rbac';
import moment from 'moment';
import RejectDrawer from 'utils/regularizeDrawer/RejectDrawer';
import { Constants } from 'views/constants';
import { usePageTitle } from 'hooks';
import { ConstantMsg } from './constants';


interface IData {
    id: number,
    log_id: number,
    employee_name: string,
    employee_code: string,
    date: string,
    checkin_time: string,
    checkout_time: string,
    duration: string,
    reason: string,
    description: string
}
const SpecificRegularizeIdDetails: React.FC = () => {

    usePageTitle(Constants.SPECIFIC_REGULARIZATION_TITLE)
    // normal page states
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [header, ] = useState<string[]>(['Employee_Name', 'Employee_Code', 'Date', 'Checkin_Time', 'Checkout_Time', 'Duration', 'Reason', 'Description']);
    const [idDetails, setIdDetails] = useState<IData | null>(null);
    const { name } = useRbac().userDetailsInfo.sub;

    // reject drawer states
    const [rejectReason, setRejectReason] = useState("");
    const [open, setOpen] = useState(false);


    const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };


    const buildData = (data: any) => {
        const checkin_time = data['checkin_time'];
        const checkout_time = data['checkout_time'];
        let all = {
            id: data['id'],
            log_id: data['log_id'],
            employee_name: '',
            employee_code: '',
            date: '',
            checkin_time: '',
            checkout_time: '',
            duration: '',
            reason: '',
            description: ''
        }

        let updatedData: IData = header?.reduce((acc: IData, cHeader: string) => {
            const currHeader = cHeader.toLocaleLowerCase();
            if (currHeader === 'checkin_time' || currHeader === 'checkout_time') {
                acc[currHeader] = moment(data['date'] + ' ' + data[currHeader]).format('hh:mm a');
                return acc;
            }
            acc[currHeader] = data[currHeader];
            return acc;
        }, all)

        if (checkin_time && checkout_time) {
            const inTime = moment(data['date'] + ' ' + checkin_time);
            const outTime = moment(data['date'] + ' ' + checkout_time)
            let total_hours = moment.duration(outTime.diff(inTime));
            updatedData['duration'] = `
                ${total_hours.hours().toString().padStart(2, '0')}:${total_hours.minutes().toString().padStart(2, '0')}
            `
        }
        setIdDetails(updatedData);
    }


    const getSpeficIdDetails = async (id: string) => {
        try {
            const response: any = await new RegularizationRequestService().getSpecificRegularizeRequest(id);
            buildData(response?.data['regularization_info']);
            setIsLoading(false);
        }
        catch (error: any) {
            setIsLoading(false);
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: getSpeficIdDetails,
            });
        }
    }



    const handleApproveOrReject = async (selection: string, selectedElem: any, rejectReason?: string,) => {
        const obj = {
            id: selectedElem?.id,
            log_id: selectedElem?.log_id,
            is_approved: selection === 'approved' || false,
            updated_by: name,
            rejectReason: rejectReason
        }
        setOpen(false);

        try {
            const hide = message.loading(ConstantMsg.LOADING_UPDATE_REGULARIZATION_REQUEST_STATUS);
            await new RegularizationRequestService().updateRegularizationRequest(obj);

            setIdDetails(null);
            setError(null);
            hide();
            message.success(ConstantMsg.SUCCESS_UPDATE_REGULARIZATION_REQUEST_STATUS);
        } catch (error: any) {
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: handleApproveOrReject,
            });
            message.error(ConstantMsg.ERROR_UPDATE_REGULARIZATION_REQUEST_STATUS);
        }
        finally {
            setIsLoading(false);
        }
    }


    function handleChange(e: ChangeEvent<HTMLTextAreaElement> | undefined) {
        if (e && e.target) setRejectReason(e?.target.value)
    }

    const cancleReject = () => {
        setOpen(false);
        setRejectReason('');
    }

    useEffect(() => {
        getSpeficIdDetails(id);
    }, [id]);


    return (
        <div>
            {
                error ? (
                    <React.Fragment>
                        <h2>{error.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{error.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) :
                    (
                        <LoadingSpinner isLoading={isLoading}>
                            <div>
                                <h2> {Constants.SPECIFIC_REGULARIZATION_REQUEST} </h2>

                                {
                                    idDetails?.employee_code !== undefined ?

                                        <div>
                                            <div className='listTableWrap'>
                                                <div className='customTbl listTable'>
                                                    <table>
                                                        <tbody>
                                                            {
                                                                header.map((currHeader) => {
                                                                    return <tr><td key={currHeader}>{currHeader.split('_').join(" ")}:</td> <td>{idDetails[currHeader.toLocaleLowerCase()]}</td></tr>
                                                                })
                                                            }
                                                        </tbody>
                                                        {(!idDetails) && (
                                                            <tr className="tableNoResultFound">
                                                            <td colSpan={45}>
                                                                <div className="noResultFound">No Result Found</div>
                                                            </td>
                                                            </tr>
                                                        )}
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='mt-10'>
                                                <Button
                                                    className='btn btnSuccess mr-10'
                                                    onClick={(e) => handleApproveOrReject('approved', idDetails, rejectReason)}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    className='btn btnDanger'
                                                    onClick={() => setOpen(true)}
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                            {open && (

                                                <RejectDrawer
                                                    open={open}
                                                    selectedElem={idDetails}
                                                    setOpen={setOpen}
                                                    cancleReject={cancleReject}
                                                    submitRejectData={handleApproveOrReject}
                                                    handleChange={handleChange}
                                                    rejectData={rejectReason}
                                                    setRejectData={setRejectReason}
                                                    title="Regularization"

                                                />
                                            )}
                                        </div>
                                        :
                                        <h2>No Regularization request </h2>
                                }

                            </div>
                        </LoadingSpinner>
                    )

            }
        </div>
    );
};

export default SpecificRegularizeIdDetails;

