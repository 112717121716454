import React, { useEffect } from "react";
import { Select } from "antd";
import { IFilterDrawerInputProps } from "models/interfaces";

const FilterDrawerSingleSelect: React.FC<{
  drawerInput: IFilterDrawerInputProps;
  filteredOptions: any;
  onChange: (filed: string, _value: any) => void;
  currentChangedKey: string | null;
}> = ({ drawerInput, filteredOptions, onChange, currentChangedKey }) => {
  const handleChange = (value: string[]) => {
    
    onChange(drawerInput.keyName, value);
  };

  const getOptions = () => {
    let _options = drawerInput?.options ? [...drawerInput?.options] : []; //NOSONAR
    if (
      drawerInput.cascadeKeyName &&
      filteredOptions[drawerInput.cascadeKeyName]
    ) {
      let checkValue = filteredOptions[drawerInput.cascadeKeyName];
      if (!Array.isArray(filteredOptions[drawerInput.cascadeKeyName])) {
        checkValue = [filteredOptions[drawerInput.cascadeKeyName]];
      }
      _options = _options.filter((each) => {
        if (checkValue.indexOf(each.parentId) !== -1) {
          return true;
        }
        return false;
      });
    }
    return _options;
  };

  useEffect(() => {
    if (currentChangedKey && currentChangedKey === drawerInput.cascadeKeyName) {
      onChange(drawerInput.keyName, null);
    }
  }, [currentChangedKey]);
  
  return (
    <React.Fragment>
      <div className="inputWrap">
        <label>{drawerInput.label}</label>
        <Select
          placeholder="Please select"
          showSearch={true}
          value={filteredOptions[drawerInput.keyName]}
          onChange={handleChange}
          className="width-100"
          options={getOptions()}
          allowClear
          filterOption={(input, option) =>
            option?.label?.toLowerCase().includes(input?.toLowerCase())
              ? true
              : false
          }
        />
      </div>
    </React.Fragment>
  );
};

export default FilterDrawerSingleSelect;
