import useScrollEffect from 'hooks/useScrollEffect';
import React from 'react'
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import NoDataFound from 'views/NoDataFound'

const colStyles = {
    name: { left: "0", minWidth: "180px" },
    grand_total: { left: "0", minWidth: "120px", width: '100px' },
    restOfTheColumns: { minWidth: "90px", }
}
interface ITableData {
    employee_name: string
    bg_color: string | null,
    grandTotal: string,
    totalHoursData: object,
    working_status: string | null,
}
interface ITableProps {
    tableHeaders: string[];
    tableData: any[];
    getSortArray: (arrayToSort: any[], sort: { keyToSort: string; direction: string; }) => any[];
    isLoading: boolean;
    entries: [string, string | null][]
}


const CommonTable: React.FC<ITableProps> = ({ tableHeaders, tableData, getSortArray, isLoading, entries}) => {
    const tableScrollableElement = useScrollEffect()

    return (
            <div className="customTbl userManagementTbl scroll-y tblStickyTHead main-table timeDelayReport" ref={tableScrollableElement}>
                <table className='totalHrReportTbl'>
                    <thead>
                        <tr>
                            {
                                tableHeaders?.map((curr_header: string) => {
                                    return (
                                        <th className={` ${curr_header === 'name' ? 'text-left colLeftSticky colLeftStickyLast' : 'text-center'}`}
                                            style={{ ...colStyles[curr_header === 'name' || curr_header === 'grand_total' ? curr_header : 'restOfTheColumns'] }}
                                            key={curr_header}>
                                            {curr_header.split('_').join(' ')}
                                        </th>
                                    )
                                })
                            }
                            <th className='colLeftSticky text-left' style={{ left: "0", minWidth: "20px" }}></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            tableData?.map(({ employee_name, totalHoursData, working_status }: any) => {
                                return (
                                    <tr key={employee_name} >
                                        {
                                            tableHeaders?.map((curr_header: string, index: number) => {
                                                let is_duration_zero = true;
                                                let classNames = curr_header === 'name' ? 'text-left colLeftSticky colLeftStickyLast' : (curr_header === 'grand_total') ? 'text-center' : 'text-center highlighted';
                                                const styles = { ...colStyles[curr_header] || { left: 0, minWidth: '50px' } }

                                                if (curr_header === 'name' || curr_header === 'grand_total') {
                                                    return (
                                                        <td key={index} className={classNames}
                                                            style={styles}>
                                                            <span> {(working_status === 'Inactive' && curr_header !== 'name') ? '0h 00m' : totalHoursData[curr_header]} </span>
                                                        </td>
                                                    )
                                                }


                                                if (!totalHoursData[curr_header]) {
                                                    classNames = classNames + ' highlighted-' + working_status?.toLowerCase()?.split(' ')?.join('-');
                                                    is_duration_zero = totalHoursData[curr_header] === '0h 00m';
                                                }
                                                else {
                                                    classNames = classNames + ' highlighted-' + totalHoursData[curr_header][1]?.toLowerCase()?.split(' ')?.join('-');
                                                    is_duration_zero = totalHoursData[curr_header][0] === '0h 00m';
                                                }




                                                if (working_status === 'Inactive' || !totalHoursData[curr_header]) {
                                                    return (
                                                        <td key={index} className={`${working_status === 'Inactive' ? classNames : 'highlighted'}`}
                                                            style={styles}>
                                                            <span></span>
                                                        </td>
                                                    )
                                                }

                                                return (
                                                    <td key={index} className={`${is_duration_zero ? classNames : 'highlighted'}`}
                                                        style={styles}>
                                                        <span>{totalHoursData[curr_header][0]}</span>
                                                    </td>
                                                )
                                            })
                                        }
                                        <td></td>
                                    </tr>
                                )
                            })
                        }

                        {(!tableData || tableData.length === 0) && <NoDataFound />}
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>

    )
}

export default CommonTable;
