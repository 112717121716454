import { Button, Input, Layout, message, Modal, Tooltip } from "antd";
import { usePageTitle } from "hooks";
import { IFilterData } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  JoinersListOffboardingService,
} from "services";
import { auth } from "utils/auth/google.security";
import FilterDrawer from "utils/commonFilter/filterDrawer/FilterDrawer";
import {
  addToolTip,
  getEncodedData,
  stringifyArrayValues,
} from "utils/commonFunction";
import {
  getCurrentDate,
  getCustomDate,
  getDateDiff,
} from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import { Constants } from "../constants";
import ExitForm from "./ExitForm";
import OffboardingModalHeader from "./OffboardingModalHeader";
import moment from "moment";
import { JoinersListOnboardingService } from "services";

const getSpecificMsg = (constMsg: string, replacableMsg: string) => constMsg.replaceAll('placeholder', replacableMsg)

export const joinersHeaders = [
  {
    title: "",
    dataIndex: "exit_emp_id",
    thClassName: "text-left",
    width: 35,
    dataRender: (
      all: any,
      _2: number,
      _3: Function,
      edit: any,
      _4: Function,
      deleteAction: any,
      processedOffboard: any,
    ) => {
      const date_of_exit = all?.date_of_exit?.split("T")[0]; //NOSONAR
      return (
        <div
          className="tblActions"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          {/* {Boolean(
            (getDateDiff(
              getCustomDate(new Date(date_of_exit)),
              getCurrentDate() 
            ) as number) > -1
          ) || */}
          {all?.exit_status === null ? 
          // (
            all?.mark_as_complete === null ?
            (
              <>
            <Tooltip title="Initiate revoking credential" placement="top">
            <div onClick={processedOffboard} >
              <i className="da icon-processing" />
            </div>
            </Tooltip>
            </>
            )
            : (
            <div style={{ margin: "0 5px" }}>
              {/* <i className="da icon-processing-done" /> */}
              <i className="da" />
            </div>
            )
          // ) 
          : (
            <div style={{ margin: "0 5px" }}>
              <i className="da" />
            </div>
          )}
           {all?.exit_status === null ? (
            <div onClick={edit} style={{ margin: "0 5px" }}>
              <i className="da icon-pencil-o-dark" />
            </div>
          ) : (
            <div style={{ margin: "0 5px" }}>
              <i className="da" />
            </div>
          )}
          {all?.exit_status === true ? (
            <div style={{ margin: "0 5px" }}>
              <i className="da" />
            </div>
          ) : (
            <div onClick={deleteAction} style={{ margin: "0 5px" }}>
              <i className="da icon-trash-o" />
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: "Exit Status",
    dataIndex: "exit_status",
    width: 100,
    thClassName: "text-left",
    dataRender: (data: boolean, all: any) => {
      return (
        <div
          className={`venueNameData text-capitalize ${data === false
            ? "text-warning"
            : data === true
              ? "text-success"
              : "text-danger"
            } `}
        >
          {data === false
            ? "Processed"
            : data === true
              ? "Completed"
              : "Pending"}
        </div>
      );
    },
  },
  {
    title: "Name",
    dataIndex: "first_name",
    width: 280,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      const last_name = all.data.singleData?.last_name;
      return (
        <div className="venueNameData">
          <div className="text-capitalize">
            {(data + " " + (last_name ?? "")).length > 37
              ? addToolTip(
                data + " " + (last_name ?? ""),
                "right",
                "default",
                37
              )
              : data + " " + (last_name ?? "")}
          </div>
        </div>
      );
    },
  },
  {
    title: "Official Email ID",
    dataIndex: "official_email_id",
    width: 280,
    thClassName: "text-left",
    dataRender: (data: string) => {
      return (
        <div className="venueNameData">
          {data?.length > 37 ? addToolTip(data, "right", "default", 37) : data}
        </div>
      );
    },
  },
  {
    title: "Date of Exit",
    dataIndex: "date_of_exit",
    width: 180,
    thClassName: "text-left",
    dataRender: (data: any, all: any) => {
      return (
        <div className="venueNameData text-capitalize">
          {getCustomDate(data, "MMM DD, YYYY")}
        </div>
      );
    },
  },
  {
    title: "Designation",
    dataIndex: "designation",
    width: 250,
    thClassName: "text-left",
    dataRender: (data: string) => {
      return (
        <div className="venueNameData text-capitalize">
          <div>{data}</div>
        </div>
      );
    },
  },
  {
    title: "Department",
    dataIndex: "department",
    width: 250,
    thClassName: "text-left",
    dataRender: (data: string) => {
      return (
        <div className="venueNameData text-capitalize">
          <div>{data}</div>
        </div>
      );
    },
  },
];

export default function OffboardingPage() {
  usePageTitle(Constants.OFFBOARDING_TITLE);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [joinersData, setJoinersData] = useState<any>([]);
  const [form, setForm] = useState<any>({
    employee_id: "",
    date_of_exit: "",
  });
  const [deleteForm, setDeleteForm] = useState<any>({
    name: "",
    exit_emp_id: "",
  });
  const [sortOptions, setSortOptions] = useState<any>({
    dataIndex: "date_of_exit",
    order: "desc",
  });
  const [dropdownData, setDropdownData] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [completeOffboard, setCompleteOffboard] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [filterParams, setFilterParams] = useState<any>({
    exit_status: null,
  });
  const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
  const [revokeOption, setRevokeOption] = useState<String | null>(null);
  const [processedOffboard, setProcessedOffboard] = useState(false);
  const [processedOffboardForm, setProcessedOffboardForm] = useState<any>({
    name: "",
    exit_emp_id: ""
  })
  useEffect(() => {
    onChangeRevokeCredentials()
  }, [revokeOption])

  const signOutWithMessage = (value: string) => {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    const msg = getEncodedData(value);
    return history.push(`/login?msg=${msg}`);
  };

  const deleteUserFromQueue = (index?: number) => {
    console.log('delete clicked')
    if (index !== undefined && index >= 0) {
      const data = joinersData[index];
      setDeleteForm({
        name: data?.first_name + (data?.last_name ? ` ${data?.last_name}` : ""),
        exit_emp_id: data?.exit_emp_id,
      });
    }
    setDeleteModal(true);
  };



  const editUserInfoCancel = () => {
    setIsModalVisible4(false);
    setForm({
      employee_id: "",
      date_of_exit: "",
    });
    setRevokeOption(null);
  };

  const processedOffboardFromQeue = (index?: number) => {
    
    if (index !== undefined && index >= 0) {
      const data = joinersData[index];
      setProcessedOffboardForm({
        name: data?.first_name + (data?.last_name ? ` ${data?.last_name}` : ""),
        exit_emp_id: data?.exit_emp_id,
      });
    }
    setProcessedOffboard(true);
  }

  const showEditUserInfoModal = (title: string, index?: number) => {
    console.log("edit clicked")
    setModalTitle(title);
    if (index !== undefined && index >= 0) {
      const data = joinersData[index];
      setForm({
        employee_id: data?.employee_id,
        date_of_exit: data?.date_of_exit,
        exit_emp_id: data?.exit_emp_id,
      });
    }
    setIsModalVisible4(true);
  };

  const processOffboard = async(exit_emp_id: number) =>{
    setProcessedOffboard(false);

    const employeeProcessedOffboard = 'employeeProcessedOffboard';
    try{
      message.loading({
        content: Constants.PROCESS_EMPLOYEE_OFFBOARDING_MESSAGE,
        duration: 0,
        key: employeeProcessedOffboard,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().processOffboarding({
        exit_emp_id
      });
      message.success({
        content: Constants.PROCESS_EMPLOYEE_OFFBOARDING_SUCCESS,
        key: employeeProcessedOffboard,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
      setIsTableLoading(true);
      await getJoinersList(filterParams);
      setIsTableLoading(false);
    }catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.PROCESS_EMPLOYEE_OFFBOARDING_FAILED,
        key: employeeProcessedOffboard,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  }


  const deleteUserInfo = async () => {
    setDeleteModal(false);
    const deleteUserFromExitQueue = "deleteUserFromExitQueue";
    try {
      message.loading({
        content: Constants.DELETE_USER_MESSAGE,
        duration: 0,
        key: deleteUserFromExitQueue,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().deleteEmployeeFromExitQueue({
        ...deleteForm,
      });
      message.success({
        content: Constants.DELETED_USER_MESSAGE,
        key: deleteUserFromExitQueue,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
      setIsTableLoading(true);
      await getJoinersList(filterParams);
      setIsTableLoading(false);
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.USER_DELETION_FAILED,
        key: deleteUserFromExitQueue,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };



  const getJoinersList = async (params?: any) => {
    setIsTableLoading(true);
    try {
      const response: any =
        await new JoinersListOffboardingService().getExitList({
          sort_column: sortOptions.dataIndex,
          sort_order: sortOptions.order,
          ...stringifyArrayValues(params),
        });
      setJoinersData(response.data?.joinee_info);
      setIsTableLoading(false);
      setError(null);
    } catch (error: any) {
      setIsTableLoading(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      setError({
        ...error,
        refreshAction: getJoinersList,
      });
    }
  };


  const getFormDropdowns = async () => {
    setIsLoading(true);
    try {
      const response: any =
        await new JoinersListOnboardingService().getJoinersFormDropdowns({});
      setDropdownData((prev: any) => {
        return {
          ...prev,
          ...response.data?.dropdown_info,
          exit_status: [
            { label: "Pending", value: null },
            { label: "Completed", value: true },
          ],
        };
      });
      setIsLoading(false);
      await getJoinersList(filterParams);
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      setError({
        ...error,
        refreshAction: getJoinersList,
      });
    }
  };

  
  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    const value = e.target.value;
    const ele: any = e.nativeEvent;
    const key: any = ele.data;
    const inputType = ele.inputType;
    if (
      key !== null &&
      inputType !== "deleteContentBackward" &&
      (value === "" || value?.length < 3)
    ) {
      if (filterParams?.search) {
        setFilterParams((prev: any) => {
          return {
            ...prev,
            search: "",
          };
        });
      }
      return;
    }
    setSearchTimeOut(
      setTimeout(
        () =>
          setFilterParams((prev: any) => {
            return {
              ...prev,
              search: value,
            };
          }),
        1200
      )
    );
  }


  const applyFilterHandler = (filterOptions: IFilterData) => {
    setFilterParams((prev: any) => {
      return {
        ...prev,
        ...filterOptions,
      };
    });
  };


  const resetFilterHandler = (filterOptions: IFilterData | null) => {
    setFilterParams((prev: any) => {
      return {
        ...prev,
        ...filterOptions,
      };
    });
  };


  const onChangeRevokeCredentials = () => {
    if (revokeOption) {
      if (revokeOption === 'revoke-all-access') {
        setCompleteOffboard(false)
        revokeImmediateAccessOfAllCredentials(form.exit_emp_id)
      }
      else if(revokeOption === 'mark-as-complete'){
        const dateOfExit = moment(form.date_of_exit)
        const currentDate = moment();

        if(currentDate.isBefore(dateOfExit)){
          setCompleteOffboard(true)
        }else{
          completeEmpOffboarding(form.exit_emp_id)
        }
      }
      else if (revokeOption === 'delete-zoho') {
        setCompleteOffboard(false)
        deleteFromZoho(form.exit_emp_id)
      } 
      else if (revokeOption === 'delete-jira') {
        setCompleteOffboard(false)
        deleteFromJira(form.exit_emp_id)
      } 
      else if (revokeOption === 'delete-workspace-user') {
        setCompleteOffboard(false)
        deleteWorkspaceUser(form.exit_emp_id)
      }
    }
  }


  const revokeImmediateAccessOfAllCredentials = async (exit_emp_id: number) => {
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_MESSAGE, 'Access crendentials'),
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      const response: any = await new JoinersListOffboardingService().revokeAccess({
        exit_emp_id
      });
      if (response.data.msg_id === 1) {
        message.success({
          content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_SUCCESS, 'Access crendentials'),
          key: saveJoineeStatusLoadingKey,
          duration: 5,
          className: "toastMsg savedSuccess",
        });
      } else {
        message.error({
          content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_FAILED, 'Access crendentials'),
          key: saveJoineeStatusLoadingKey,
          duration: 5,
          className: "toastMsg savedSuccess",
        });
      }

    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_FAILED, 'Access crendentials'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  const deleteFromZoho = async (exit_emp_id: number) => {
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_MESSAGE, 'Zoho account'),
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().deleteZoho({
        exit_emp_id
      });
      message.success({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_SUCCESS, 'Zoho account'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_FAILED, 'Zoho account'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  const deleteFromJira = async (exit_emp_id: number) => {
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_MESSAGE, 'Jira account'),
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().deleteJira({
        exit_emp_id
      });
      message.success({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_SUCCESS, 'Jira account'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_FAILED, 'Jira account'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  const deleteWorkspaceUser = async (exit_emp_id: number) => {
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_MESSAGE, 'Workspace user'),
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().deleteWorkspaceUser({
        exit_emp_id
      });
      message.success({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_SUCCESS, 'Workspace user'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: getSpecificMsg(Constants.ACCESS_CREDENTIALS_DELETION_FAILED, 'Workspace user'),
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  const completeEmpOffboarding = async (exit_emp_id: number) => {
    setCompleteOffboard(false)
    setIsModalVisible4(false); 
    setRevokeOption(null);
    
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.COMPLETE_EMPLOYEE_OFFBOARDING_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOffboardingService().completeOffboarding({
        exit_emp_id
      });
      message.success({
        content: Constants.COMPLETE_EMPLOYEE_OFFBOARDING_SUCCESS,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
      setJoinersData((prevData:any) => prevData.filter((row:any) => row.exit_emp_id !== exit_emp_id))
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.COMPLETE_EMPLOYEE_OFFBOARDING_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  useEffect(() => {
    getFormDropdowns();
  }, []);

  useEffect(() => { 
    if (isLoading === false) getJoinersList(filterParams);
  }, [filterParams]);

  useEffect(() => {
    return () => {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
    };
  }, [searchTimeOut]);


  const getEmpNameByExitEmpId = (data:any, exitEmpId:any) => {
    const employee = data?.find((item:any) => item.exit_emp_id == exitEmpId);
    return employee ? employee.first_name + ' ' + employee.last_name : null;
  };

  return (
    <LoadingSpinner isLoading={isLoading ? isLoading : false}>
      <Layout className="mainInnerLayout">
        {error ? (
          <React.Fragment>
            <h2>{error.code} Error</h2>
            <div className="errorContent">
              <div className="errorContentInner">
                <span className="error-img"></span>
                <div>
                  <h3>{error.message}</h3>
                  <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>{Constants.OFFBOARDING}</h2>
            <div className="innerHeader">
              <div className="ant-row alignItemsCenter">
                <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                  <h3 style={{ width: "50%" }}>{Constants.EXIT_QUEUE}</h3>
                  <Input
                    placeholder={Constants.SEARCH_LABEL_EXIT}
                    allowClear
                    onChange={handleChange}
                  />
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="innerHeaderRight flx alignItemsFlexEnd">
                    <button
                      className="addUserBtn mr-10"
                      type="button"
                      disabled={isTableLoading}
                      style={
                        isTableLoading
                          ? {
                            backgroundColor: "#5b719c",
                            cursor: "not-allowed",
                          }
                          : {}
                      }
                      onClick={() =>
                        showEditUserInfoModal(Constants.ADD_MEMBER_INFO)
                      }
                    >
                      <i className="da icon-add-user-o"></i>
                      {Constants.MEMBER}
                    </button>
                    <FilterDrawer
                      disabled={false}
                      drawerInputs={[
                        {
                          type: "singleSelect",
                          label: "Exit Status",
                          keyName: "exit_status",
                          options: dropdownData?.exit_status,
                          show: true,
                          defaultValue: null,
                        },
                        {
                          type: "multiSelect",
                          label: "Department",
                          keyName: "department_info",
                          options: dropdownData?.department_info,
                          show: true,
                        },
                        {
                          type: "multiSelect",
                          label: "Designation",
                          keyName: "designation_info",
                          options: dropdownData?.designation_info,
                          show: true,
                        },
                      ]}
                      onApplyFilter={applyFilterHandler}
                      onResetFilter={resetFilterHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="customTbl userManagementTbl scroll-y tblStickyTHead">
                <CommonReportTable
                  tableClass="customTbl scroll-y main-table"
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    getJoinersList({
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                      ...filterParams,
                    });
                    setSortOptions((prev: any) => {
                      return {
                        dataIndex: "date_of_exit",
                        order: prev?.order === "desc" ? "asc" : "desc",
                      };
                    });
                  }}
                  sortField={sortOptions}
                  data={joinersData}
                  otherData={joinersData}
                  headers={joinersHeaders}
                  loading={isTableLoading}
                  showEditUserInfoModal={showEditUserInfoModal}
                  deleteUserFromQueue={deleteUserFromQueue}
                  processOffboard = {processedOffboardFromQeue}
                />
              </div>
            </div>

            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={deleteModal}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {Constants.DELETE_USER_CONFIRMATION_MESSAGE_OPEN}{" "}
                <span style={{ color: "red" }}>Delete</span>{" "}
                {Constants.DELETE_USER_CONFIRMATION_MESSAGE_CLOSE.replace(
                  "fullname",
                  deleteForm.name
                )}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setDeleteModal(false);
                  }}
                // disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  // disabled={isUpdatingStatus}
                  onClick={() => deleteUserInfo()}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>

            <Modal
              closable={false}
              title={false}
              footer={false}
              visible={isModalVisible4}
              centered
              className="editModal"
              width={600}
            >
              <OffboardingModalHeader modalTitle={modalTitle} revokeOption={revokeOption} setRevokeOption={setRevokeOption} form={form} />
              <ExitForm
                modalTitle={modalTitle}
                filterParams={filterParams}
                data={joinersData}
                editUserInfoCancel={editUserInfoCancel}
                form={form}
                setForm={setForm}
                getJoinersList={getJoinersList}
                setIsLoading={setIsTableLoading}
                setError={setError}
                signOutWithMessage={signOutWithMessage}
              />
            </Modal>


            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={completeOffboard}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {/* {Constants.DELETE_USER_CONFIRMATION_MESSAGE_OPEN}{" "} */}
                Are you sure to mark {getEmpNameByExitEmpId(joinersData, form.exit_emp_id)}'s offboarding as complete before exit date?
                {/* <span style={{ color: "red" }}>Complete Offboarding of</span>{" "} */}
                {/* {Constants.DELETE_USER_CONFIRMATION_MESSAGE_CLOSE.replace(
                  "fullname",
                  getFirstNameByExitEmpId(joinersData, form.exit_emp_id)
                )} */}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setCompleteOffboard(false);
                    setRevokeOption(null);
                  }}
                // disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  // disabled={isUpdatingStatus}
                  onClick={() => {
                    setIsModalVisible4(false); 
                    setRevokeOption(null);
                    completeEmpOffboarding(form.exit_emp_id);
                  }}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>



            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={processedOffboard}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {/* {Constants.DELETE_USER_CONFIRMATION_MESSAGE_OPEN}{" "} */}
                Are you sure to process {processedOffboardForm.name}'s offboarding ?
                {/* <span style={{ color: "red" }}>Complete Offboarding of</span>{" "} */}
                {/* {Constants.DELETE_USER_CONFIRMATION_MESSAGE_CLOSE.replace(
                  "fullname",
                  getFirstNameByExitEmpId(joinersData, form.exit_emp_id)
                )} */}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setProcessedOffboard(false);
                  }}
                // disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  // disabled={isUpdatingStatus}
                  onClick={() => {
                    setProcessedOffboard(false); 
                    processOffboard(processedOffboardForm.exit_emp_id)
                  }}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>
          </React.Fragment>
        )}
      </Layout>
    </LoadingSpinner>
  );
}
