import React, { useState, useEffect, useMemo } from 'react';
import { message, Layout } from 'antd'
import moment from 'moment';
import { useRbac } from 'features/rbac/rbac';
import RegularizeDrawer from './RegularizeDrawer';
import { AttendanceData } from 'models/interfaces';
import { Constants } from 'views/constants';
import { IRegularizeData } from 'models/interfaces/Regularize/regularizeOptions.model';
import { CalanderComponent } from './CalanderComponent';
import { usePageTitle } from 'hooks';
import { useApiServices } from 'hooks/useApiServices';
import { convertToDateTime } from 'utils/commonFunction';


const defaultRegularisationData = () => ({ date: null, inTime: null, outTime: null, reason: null, desc: '', duration: null, id: null })
interface IInTimeAfterOutTime { bool: boolean, from: string, msg: string }

const DelayAttendanceReport = () => {

    usePageTitle(Constants.DAILY_ATTENDANCE_TITLE);

    // Normal state states
    const { biometric_emp_code, name } = useRbac().userDetailsInfo.sub;
    const [currentMonth, setCurrentMonth] = useState<moment.Moment>(moment());
    const props = useMemo(() => ({ employee_code: biometric_emp_code, month: currentMonth.month() + 1 }), [biometric_emp_code, currentMonth])
    
    // Regularization drawer states
    const [open, setOpen] = useState(false);
    const [regularisationData, setRegularisationData] = useState<IRegularizeData>(defaultRegularisationData());
    const [inTimeAfterOutTime, setInTimeAfterOutTime] = useState<IInTimeAfterOutTime>({ bool: false, from: '', msg: '' });
    const [eventsArray, setEventsArray] = useState<any>({});

    const { isLoading, isError, data, fetchData, updateData } = useApiServices()

    


    // BULDING EVENT DATAS
    function buildingTableDatas(attendanceReport: any, wfhData: any[], holidayData: string []) {
        
        let eventsByDateObj = {}
        attendanceReport['attendance_data']?.forEach((cell: AttendanceData) => {
            let { in_time, out_time, duration, date } = cell;

            const inTime = convertToDateTime(date, in_time);
            const inTimeString = !in_time? '--' : inTime?.format('hh:mm a');
            const outTime = convertToDateTime(date, out_time);
            const outTimeString = !out_time? '--' : outTime?.format('hh:mm a');
            
            const durationMoment = convertToDateTime(date, duration);
            duration = !duration? '--' : durationMoment?.format('hh:mm a');;

            const weekDay = moment(date).weekday();
            
            let showRegularizationButton: boolean = true;

            if (weekDay === 0 || weekDay === 2 || weekDay === 5 || weekDay === 6)
                if (inTimeString === '--' && outTimeString === '--')
                    showRegularizationButton = false;

            if (showRegularizationButton) {
                eventsByDateObj = {
                    ...eventsByDateObj,
                    [date]: {
                        todayEvents: [
                            {
                                title: 'In Time  ',
                                date: inTime,
                                time: inTimeString
                            },
                            {
                                title: 'Out Time  ',
                                date: outTime,
                                time: outTimeString
                            },
                            {
                                title: 'Duration  ',
                                date: durationMoment,
                                time: duration
                            }
                        ],
                        id: cell.id ?? null,
                        regularisation_status: cell.regularization_status,
                        wasAbsent: !in_time && !out_time
                    }
                }
            }
        });
        
        const weeklyWfhDays = [2, 5]
        const weeklyWfoDays = [1, 3, 4]

        wfhData?.forEach(({from_date, to_date, days}: any) => {
            const fromDate = moment(from_date);
            const toDate = moment(to_date);
            
            
            while (!fromDate.isAfter(toDate)) {
                const weekday = fromDate.weekday()
                const formattedFromDate = fromDate.format('YYYY-MM-DD');
                const dateEvent = eventsByDateObj[formattedFromDate];
                
                
                if (
                    (weeklyWfoDays.includes(weekday) && (dateEvent?.wasAbsent || !dateEvent))
                    ||
                    (weeklyWfhDays.includes(weekday) && !dateEvent)
                ){

                    if (days?.includes(weekday) || !days){
                        eventsByDateObj = {
                            ...eventsByDateObj,
                            [formattedFromDate]: {
                                wfhStatus: true,
                                todayEvents: null
                            }
                        }
                    }
                }
                fromDate.add(1, 'days');
            }
        });

        holidayData?.forEach((curr_obj) => {
            const holiday = moment(curr_obj, 'YYYY-MM-DD').format('YYYY-MM-DD');

            eventsByDateObj = {
                ...eventsByDateObj,
                [holiday]: {
                    holidayStatus: true,
                    todayEvents: null
                }
            }
        })
        
        return eventsByDateObj;
    }
    
    
    // API CALL - POSTING Regularisation DATA
    const submitRegularizeData = async () => {
        const obj = {
            employee_code: biometric_emp_code,
            employee_name: name,
            log_id: regularisationData.id,
            checkin_time: regularisationData.inTime?.format('HH:mm:ss') ?? '',
            checkout_time: regularisationData.outTime?.format('HH:mm:ss') ?? '',
            reason: regularisationData.reason?.label ?? '',
            description: regularisationData.desc
        }
        setRegularisationData(defaultRegularisationData())
        setOpen(false);

        await updateData('CREATE_REGULARIZATION_REQUEST', obj);

        if (data.msg_id === -1) {
            // return message.error(ConstantMsg.ERROR_REGULARIZATION_REQUEST)
        }

        await fetchData('GET_DAILY_ATTENDANCE_DATA', props);
    };


    // TURN OFF RegularisaTION DRAWER
    const cancleRegularisation = () => {
        setOpen(false);
        setRegularisationData(defaultRegularisationData())
    }


    useEffect(() => {
        if(data){
            const { attendance_report, wfh_data, holiday_data } = data;
            const buildData = buildingTableDatas(attendance_report || {}, wfh_data || [], holiday_data || []);
            setEventsArray(buildData);
        }
    }, [data])

    useEffect(() => {
        fetchData('GET_DAILY_ATTENDANCE_DATA', props)
    }, [fetchData, props]);


    return (
        <Layout className="ant-layout daily-attendance-board mainInnerLayout">
            {
                isError ? (
                    <React.Fragment>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : 
                (

                    <>
                        <RegularizeDrawer
                        cancleRegularisation={cancleRegularisation}
                        regularisationData={regularisationData}
                        setRegularisationData={setRegularisationData}
                        open={open}
                        setOpen={setOpen}
                        submitRegularizeData={submitRegularizeData}
                        inTimeAfterOutTime={inTimeAfterOutTime}
                        setInTimeAfterOutTime={setInTimeAfterOutTime}
                        />

                        <CalanderComponent
                        isTableLoading={isLoading}
                        currentMonth={currentMonth}
                        setCurrentMonth={setCurrentMonth}
                        regularisationData={regularisationData}
                        setRegularisationData={setRegularisationData}
                        setOpen={setOpen}
                        eventArray={eventsArray}
                        setInTimeAfterOutTime={setInTimeAfterOutTime}
                        />
                    </>
                )
            }
        </Layout>
    );
};

export default DelayAttendanceReport;