import React, { ChangeEvent, useMemo, useState } from "react";
import { Button, Drawer, Space, Tooltip, Upload, UploadFile, UploadProps, message } from "antd";
import type { Moment } from 'moment';
import moment from "moment";
import { InboxOutlined } from '@ant-design/icons';
import { useRbac } from "features/rbac/rbac";
import { IWFHDrawerProps, IWfhDrawerInputProps } from "models/interfaces/WfhApply/wfhApply.model";
import WfhDrawerTextarea from "utils/WfhDrawer/WfhDrawerTextArea";
import WfhDrawerDatePicker from "utils/WfhDrawer/WfhDrawerDatePicker";
import WfhDrawerMultiselect from "utils/WfhDrawer/WfhDrawerMultiselect";
const { Dragger } = Upload;



const WfhDrawer: React.FC<IWFHDrawerProps> =
    ({ wfhData, setWfhData, setIsWfhOpen, isWfhOpen, submitWfhData, cancelWfhApply, weekDaysToShow, fileList, setFileList }) => {

        // const [fileList, setFileList] = useState<UploadFile[]>([]);
        const { biometric_emp_code } = useRbac().userDetailsInfo.sub;

        const drawerInputs: IWfhDrawerInputProps[] = [
            {
                disable: false,
                defaultValue: true,
                keyName: "start_date",
                label: "Start Date: ",
                options: [],
                default: moment(),
                type: 'datepicker'
            },
            {
                disable: false,
                defaultValue: true,
                keyName: "end_date",
                label: "End Date: ",
                options: [],
                default: moment(),
                type: 'datepicker'
            },
            {
                type: "multiSelect",
                disable: false,
                defaultValue: false,
                keyName: "days",
                label: "Days",
                options: weekDaysToShow.sort((curr: any, next: any ) => curr.value - next.value),
                default: ''
            },
            {
                disable: false,
                defaultValue: false,
                keyName: "reason",
                label: "Reason",
                options: [],
                default: '',
                type: 'textarea'
            },
            {
                disable: false,
                defaultValue: false,
                keyName: "documents",
                label: "Documents",
                options: [],
                default: '',
                type: 'dragger'
            },
        ]
        

        const showDaysSelecter = useMemo(() => {
            if (wfhData.start_date && wfhData.end_date){
                    let weekdayCount = 0;
                    let start = wfhData.start_date.clone();
                    let end = wfhData.end_date.clone();
                
                    while (start.isSameOrBefore(end)) {
                        if (start.weekday() !== 0 && start.weekday() !== 6) {
                            weekdayCount++;
                        }
                        start.add(1, 'days');
                    }
                
                    return weekdayCount > 2;
            };
            return false;
        }, [wfhData.start_date, wfhData.end_date]);


        // Disable start dates
        const disabledStartDate = (current: Moment | null): boolean => {
            const today = moment().startOf('day');
            if (!current) return false;
            if ((current.weekday() === 0 || current.weekday() === 6) || current.isBefore(today)) return true;
            return !!(wfhData.end_date && current.isAfter(wfhData.end_date, 'day'));
        };


        // Disable end dates
        const disabledEndDate = (current: Moment | null): boolean => {
            const today = moment().startOf('day');
            if (!current) return false;
            if ((current.weekday() === 0 || current.weekday() === 6) || current.isBefore(today)) return true;
            return !!(wfhData.start_date && current.isBefore(wfhData.start_date, 'day'));
        };


        const defaultWfhData = () => ({ start_date: null, end_date: null, reason: '', id: biometric_emp_code, document: null, days: null })




        const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'image/webp'];

        const props: UploadProps = {
            name: 'file',
            multiple: false,
            accept: '.jpg,.jpeg,.png,.pdf,.webp',

            onRemove: file => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                setWfhData({ ...wfhData, document: null });
            },
            onDrop(e) {
            },
            beforeUpload: (file) => {
                if (!allowedFileTypes.includes(file.type)) {
                    message.error({
                        content: 'You can only upload JPG, JPEG, PNG, PDF, or WEBP files!',
                        duration: 5,
                        key: props,
                        className: "toastMsg savedError",
                    });
                }
                else{
                    setFileList([file]);
                    setWfhData({ ...wfhData, document: file });
                }
                return false;
            },
            fileList
        };


        const handleChange = (keyName: string, value: any, e?: ChangeEvent<HTMLTextAreaElement> | undefined) => {
            let updatedValue = value;

            if (keyName === 'days') {

                if (updatedValue.length === weekDaysToShow.length || updatedValue.length === 0)
                    updatedValue = null;
                else
                    updatedValue = value.sort((curr: number, next: number) => curr - next);
            }

            if((keyName === 'start_date' || keyName === 'end_date') && (wfhData.start_date && wfhData.end_date) && wfhData.start_date.diff(wfhData.end_date, 'days')){
                setWfhData({ ...wfhData, [keyName]: updatedValue, days: null });
                return;
            }

            if(keyName !== 'document')
                setWfhData({ ...wfhData, [keyName]: updatedValue });
            else
                setWfhData({ ...wfhData, [keyName]: null });


        }



        const onClose = () => {
            setIsWfhOpen(false);
            setFileList([]);
            setWfhData(defaultWfhData())
        };

        const enableSubmit = () => {
            return (wfhData.start_date
                &&
                wfhData.end_date
                && (wfhData.reason.trim().length > 0)
            )
        }
        


        return (
            <React.Fragment>
                <Button type="primary" onClick={() => { setIsWfhOpen(true) }} style={{ marginLeft: '15px' }}>Apply for WFH</Button>
                <Drawer
                    title="Apply for Work From Home"
                    placement="right"
                    onClose={onClose}
                    visible={isWfhOpen}
                    className="filterDrawerWrap drawerCustom"
                    
                    closeIcon={
                        <Tooltip title="Close Filter Drawer" placement="left">
                            <i className="da icon-arrow-right-dark"></i>
                        </Tooltip>
                    }
                >
                    {
                        drawerInputs.map((curr) => {


                            if (curr.type === 'datepicker') {
                                return (
                                    <WfhDrawerDatePicker
                                        drawerInput={curr}
                                        handleChange={handleChange}
                                        wfhData={wfhData}
                                        key={curr.keyName}
                                        disabledDate={curr.keyName === 'start_date' ? disabledStartDate : disabledEndDate}
                                    />
                                )
                            }
                            if (curr.type === 'multiSelect') { 

                                if (showDaysSelecter)
                                    return <WfhDrawerMultiselect
                                        key={curr.keyName}
                                        drawerInput={curr}
                                        handleChange={handleChange}
                                        wfhData={wfhData}
                                        />
                                else 
                                    return null

                            }

                            if (curr.type === 'textarea') {
                                return (
                                    <WfhDrawerTextarea
                                        key={curr.keyName}
                                        drawerInput={curr}
                                        handleChange={handleChange}
                                        wfhData={wfhData}
                                        setWfhData={setWfhData}
                                    />
                                )
                            }
                            return (
                                <div className="inputWrap" key={curr.keyName}>
                                    <label> {curr.label}: </label>

                                    <Dragger {...props} accept='.jpg,.jpeg,.png,.pdf' maxCount={1}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    </Dragger>
                                </div>
                            )
                        })
                    }

                    <Space wrap className="btnsRight" style={{ marginTop: '20px' }}>
                        <Button type="link" onClick={cancelWfhApply} className="linkBtn">Cancel</Button>
                        <Button type="primary" onClick={submitWfhData} disabled={!enableSubmit()}>Apply</Button>
                    </Space>

                </Drawer>

            </React.Fragment>
        );
    };

export default WfhDrawer;