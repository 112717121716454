import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import UploadComponent from './UploadComponent';
import { usePageTitle } from 'hooks';
import { Constants } from 'views/constants';
import moment from 'moment';
import { useState } from 'react';
import { Layout, UploadFile } from 'antd';
import { useApiServices } from 'hooks/useApiServices';

export default function UploadDocuments() {
    usePageTitle(Constants.UPLOAD_DOCUMENTS_TITLE);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const { updateData, isLoading } = useApiServices()

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current && (current > moment().endOf('day') || current.isSame(moment(), 'day'));
    };

    const onChange = (date: any, dateString: string) => {
        setSelectedDate(dateString); // Update selectedDate state with the selected date string
    };
    const isDisabled = () => (selectedDate === '' || isLoading)

    const handleUpload = async (fileList: UploadFile[]) => {
        const formData = new FormData();

        if (fileList?.[0]) {
            formData.append('file', fileList[0] as unknown as Blob)
        }

        await updateData('UPLOAD_BIOMETRIC_ATTENDANCE_DOCUMENT', formData, { date_for: selectedDate })
        setSelectedDate('');
    };


    return (
        <Layout className="mainInnerLayout full-screen uploadDocument">
            <div className="innerHeader headerSingle">
                <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                    <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter" style={{marginBottom: '20px'}}>
                        <h3>{Constants.UPLOAD_ATTENDANCE_DOCUMENTS}</h3>
                    </div>
                </div>
                <DatePicker
                onKeyDown={(e) => e.preventDefault()}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
                onChange={onChange}
                value={selectedDate ? moment(selectedDate) : null}
                style={{ "width": '20rem' }}
                showToday={false}
                />
            </div>


            <UploadComponent
            isDisabled={isDisabled}
            handleUpload={handleUpload}
            acceptedTypes='.csv, .xlxs'
            />
        </Layout>
    );
}




