import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { IWFHData, IWfhDrawerInputProps } from 'models/interfaces/WfhApply/wfhApply.model';


interface IWfhInputTextArea{
  drawerInput: IWfhDrawerInputProps
  handleChange: (keyName: string, value: string) => void
  wfhData: IWFHData;
  setWfhData: React.Dispatch<React.SetStateAction<IWFHData>>,
  isNotRequired?: boolean
}

const WfhDrawerTextarea:React.FC<IWfhInputTextArea> = 
({drawerInput, handleChange, wfhData, setWfhData, isNotRequired}) => {
  return (
    <div className="inputWrap">
        <label> Reason: {!isNotRequired && (<span className="req">*</span>)}</label>
        <TextArea 
          placeholder='Write the reason'
          style={{color: 'black'}}
          onChange={(e) => handleChange(drawerInput.keyName, e?.target?.value)} 
          value={wfhData[drawerInput.keyName]}
        />
      </div>
  )
}

export default WfhDrawerTextarea;
