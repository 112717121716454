import { message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react"
import { ConstantMsg } from "views/Biometric/constants";
import SignoutWithMsg from "views/Login/SignoutWithMsg";
import { apiServicesUtils } from "./apiServicesUtils";
// import { useLocation } from "react-router";
// import getFetchEndPoints from "./uriEndPointLink";
// import mappedEndPoints from "./uriEndPointLink";
// const location = useLocation();


export const useApiServices = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [data, setData] = useState<any>(null);
    const [updateResponse, setUpdateResponse] = useState<any>(null);
    const [isError, setIsError] = useState<any>(null);

    const fetchData = useCallback(async (task: string, params?: any, args?: any) => {
            if(!isLoading)
                setIsLoading(true);
            
            try {
                const className = apiServicesUtils[task]['class']
                const methodName = apiServicesUtils[task]['method']
                const classInstance = new className();
                
                if(classInstance && typeof classInstance[methodName] === 'function'){
                    const response: any = await classInstance[methodName](params)
                    setData(response?.data); 
                    
                    
                    if(response && (response.status === 500 || response.status === 503)){
                        setIsError({
                            ...response?.data,
                            refreshAction: fetchData,
                        });
                    }
                }
    
            } catch (error: any) {
                if (error.code === 401) {
                    SignoutWithMsg().signOutWithMessage("You are not authorized");
                }
                setIsError({
                    ...error,
                    refreshAction: fetchData,
                })
            }
            finally{
                setIsLoading(false);
            }
    },  []);


    const updateData = useMemo(() => {
        return async (task: string, params?: any, args?: any) => {
            const hide = message.loading(ConstantMsg['LOADING_' + task]);
            
            try {
                const className = apiServicesUtils[task]['class']
                const methodName = apiServicesUtils[task]['method']
                const classInstance = new className()
                
                if(classInstance && typeof classInstance[methodName] === 'function'){
                    
                    const response: any = await classInstance[methodName](params, args);
                    setUpdateResponse(response?.data);
                    hide();
                   
                    
                    if(response && (response.status === 200 || response.status === 201)){
                        message.success(ConstantMsg['SUCCESS_' + task]);
                    }else{
                        message.error(ConstantMsg['ERROR_' + task]);
                    }
                    return response?.data
                }
                
            } catch (error: any) {
                if(error.code === 404){
                    message.error({...ConstantMsg['ERROR_' + task], content: error?.error?.response?.data?.msg});
                }else{
                    message.error(ConstantMsg['ERROR_' + task]);
                }

                if (error.code === 401) {
                    SignoutWithMsg().signOutWithMessage("You are not authorized");
                }
                setIsError({
                    ...error,
                    refreshAction: fetchData,
                })
            }
        }
    }, [fetchData]);


//    useEffect(() => {
    
//     const path = location.pathname;
//     const pathArray = path.split('/');
//     const actualPage = pathArray[pathArray.length - 1];
//     console.log(actualPage);
//     const 
    
    
//    }, [location])

    return {isLoading, isError, data, fetchData, updateData, updateResponse};
}