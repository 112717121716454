import { Button, Input, Layout, Modal, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router';
import { LeaveReconcilliationService } from 'services/leaveReconcilliation/leaveReconcilliationMaster.service'
import { auth } from 'utils/auth/google.security';
import { getEncodedData } from 'utils/commonFunction';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { Constants } from 'views/constants';
import LeaveReconcilliationMasterForm from './LeaveReconcilliationMasterForm';
import LeaveReconcilliationMasterTable from './LeaveReconcilliationMasterTable';
import { DeleteOutlined } from '@ant-design/icons';
import { ConstantMsg } from './constants';
import moment from 'moment';
import { usePageTitle } from 'hooks';

const LeaveReconcilliationMaster = () => {

    const basicHeaders = ['update', 'employee_id', 'employee_name', 'designation', 'email'];
    usePageTitle(Constants.LEAVE_RECONCILIATION_MASTER_TITLE);

    // Normal page states
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [tableHeaders, setTableHeaders] = useState<string[]>(basicHeaders);
    const [tableData, setTableData] = useState<any[]>([]);
    const [error, setError] = useState<any>(null);
    const [disableAddColumnButton, setDisableAddcolumnButton] = useState<boolean>(true);
    const history = useHistory();

    // Form and other states
    const [masterFormVisible, setMasterFormVisible] = useState<{ visible: boolean, text: string }>({ visible: false, text: '' });
    const [form, setForm] = useState<any>({ employee_id: '', employee_name: '', designation: null, email: '' });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dropdownData, setDropdownData] = useState<any[]>([]);
    const [enableConfirmDeleteModel, setEnableConfirmDeleteModel] = useState<boolean>(false);
    const [enableAddNewColumnModel, setEnableAddNewYearModel] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<any>({ searchText: '', sortTypes: {} });
    const [searchTimeOut, setSearchTimeOut] = useState<any>(null);


    const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("employee_code");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };

    // STRUCTURING THE TABLE DATA AND HEADER
    function buildTableHeaderAndData(fetchedData: any[]) {
        const today = moment()
        if (fetchedData && fetchedData.length > 0) {
            // building headers
            const headersWithOpeningBalance =
                Object.keys(fetchedData[0])
                    .filter((curr_key) => curr_key.includes('opening_balance'))
                    .sort((a: string, b: string) => a.localeCompare(b));


            const year = today.month() >= 3 && headersWithOpeningBalance.includes(`opening_balance_${today.year()}`) ? today.year() : today.year() - 1;
            const index = headersWithOpeningBalance.indexOf(`opening_balance_${year}`);
            let disableColumnButton = false;
            let updatedHeaders = [...basicHeaders, ...headersWithOpeningBalance.slice(index), 'delete'];

            // building data
            const data = fetchedData?.map((curr_row) => {
                let updated_master_data = updatedHeaders.reduce((acc, header) => {
                    let sendingData;
                    if (header.toLocaleLowerCase() === 'update') {
                        sendingData = <i className="da icon-pencil-o-dark text-center pointer"></i>
                    }
                    else if (header.toLocaleLowerCase() === 'delete') {
                        sendingData = <DeleteOutlined style={{ color: 'red' }} className='text-center pointer' />
                    }
                    else {
                        sendingData = curr_row[header] && curr_row[header];
                    }
                    acc[header] = sendingData;
                    return acc;
                }, {});
                return updated_master_data;
            });



            if (headersWithOpeningBalance) {
                const year = Number(headersWithOpeningBalance[headersWithOpeningBalance.length - 1].split('_')[2]);
                if (today.month() >= 3) {
                    disableColumnButton = !(year < new Date().getFullYear() + 1);
                } else {
                    disableColumnButton = !(year < new Date().getFullYear());
                }
            }

            const updatedFormFields =
                updatedHeaders
                    .slice(1, updatedHeaders.length - 1)
                    .reduce((acc, curr: string) => {
                        if (curr === headersWithOpeningBalance[headersWithOpeningBalance.length - 1]) {
                            acc[curr] = 0;
                        } else
                            if (curr.includes('opening_balance')) {
                                acc[`opening_balance_${moment().month() <= 2 ? moment().year() - 1 : moment().year()}`] = 0;
                            } else if (curr === 'designation') {
                                acc[curr] = null;
                            } else {
                                acc[curr] = '';
                            }
                        return acc;
                    }, {});

            setDisableAddcolumnButton(disableColumnButton);
            setForm(updatedFormFields);
            setTableHeaders(updatedHeaders);
            setTableData(data);
        }
    }


    // PULLING DESIGNATION DETAILS FOR FORM DROPDOWN
    const getFormDropdowns = async () => {
        try {
            const response: any = await new LeaveReconcilliationService().getAllDesignations();
            setIsLoading(false);
            setError(null);
            setDropdownData(response.data?.designtions);
        } catch (error: any) {
            setIsLoading(false);
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: getFormDropdowns,
            });
        }
    };


    // PULLING MASTER RECONCILIATION PAGE DATA
    const getLeaveReconcilliationMasterData = async (params?: any) => {
        setIsTableLoading(true);
        try {
            const response: any = await new LeaveReconcilliationService().getLeaveReconcilliationMasterSheetData(params);

            buildTableHeaderAndData(response.data?.masterData);
            setError(null);
        } catch (error: any) {
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: getLeaveReconcilliationMasterData,
            });
        }
        finally {
            setIsTableLoading(false);
        }
    };


    // Saving new leave data to master table
    const addUpdateOrDeleteLeaveDataInMasterTable = async (e: any, formData?: any) => {
        e.preventDefault();
        const task = masterFormVisible.text;
        setMasterFormVisible({ visible: false, text: '' })

        try {
            message.loading(ConstantMsg.LOADING_SAVE_MASTER_LEAVE_DATA);
            const response: any = await new LeaveReconcilliationService().saveNewDataToMasterTable({ task, data: form });

            if (response.data.msg_id === -1) {
                message.error(ConstantMsg.ERROR_SAVE_MASTER_LEAVE_DATA);
            } else {
                setError(null);
                message.success(ConstantMsg.SUCCESS_SAVE_MASTER_LEAVE_DATA);
                handleClickEditUserInfoCancel();
                await getLeaveReconcilliationMasterData()
            }

        } catch (error: any) {
            message.error(ConstantMsg.ERROR_SAVE_MASTER_LEAVE_DATA);
            setError({
                ...error,
                refreshAction: addUpdateOrDeleteLeaveDataInMasterTable,
            });
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
        }
    };


    // ADDING NEW COLUMN IN THE SATER RECONCILIATION TABLE
    const handleCreateNewColumn = async (e: any) => {
        e.preventDefault();
        const headersWithOpeningBalance = tableHeaders.filter((elem) => elem.includes('opening_balance'));
        let year = new Date().getFullYear();
        if (headersWithOpeningBalance) {
            year = Number(headersWithOpeningBalance[headersWithOpeningBalance.length - 1]?.split('_')[2]) + 1;
        }

        try {
            message.loading(ConstantMsg.LOADING_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN);

            const response: any = await new LeaveReconcilliationService().createNewColumn({ year });

            if (response.data.msg_id === -1) {
                message.error(ConstantMsg.ERROR_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN);
            } else {
                setError(null);
                message.success(ConstantMsg.SUCCESS_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN);
                handleClickEditUserInfoCancel();
                await getLeaveReconcilliationMasterData()
            }
        } catch (error: any) {
            message.error(ConstantMsg.ERROR_CREATE_CURRENT_FINANCIAL_YEAR_COLUMN);
            setError({
                ...error,
                refreshAction: addUpdateOrDeleteLeaveDataInMasterTable,
            });
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
        }
    };


    const getFormDefault = () => {
        const slicedHeader = tableHeaders.slice(1, tableHeaders.length - 1);
        return (
            slicedHeader
                .reduce((acc, curr: string, index: number) => {

                    if (curr.includes('opening_balance')) {
                        acc[curr] = 0;
                    } else if (curr === 'designation') {
                        acc[curr] = { label: 'Select', value: 0 };
                    } else {
                        acc[curr] = '';
                    }
                    return acc;
                }, {})
        )
    }


    const handleClickAddNewData = async () => {
        setMasterFormVisible({ visible: true, text: 'add' });
    }


    // UPDATE DROPDOWN FUNCTIONALITY
    const handleClickUpdateButton = (emp_master_leave_data: any) => {
        setMasterFormVisible({ visible: true, text: 'update' });
        const formWithExistingData =
            Object.keys(form).reduce((acc, curr, index) => {
                acc[curr] = emp_master_leave_data[curr];
                if (index === Object.keys(form).length - 1) {
                    acc[curr] = emp_master_leave_data[curr] || 0;
                }
                return acc;
            }, {});
        setForm(formWithExistingData);
    }

    const handleClickEditUserInfoCancel = () => {
        setForm(getFormDefault());
        setMasterFormVisible({ visible: false, text: '' });
    }


    // UPDATE INLINE FUNCTIONALITY
    const handleClickUpdateButtonInline = (emp_master_leave_data: any) => {
        if (masterFormVisible.visible || masterFormVisible.text !== 'update') {
            setMasterFormVisible({ visible: false, text: 'update' });
        }

        const formWithExistingData =
            Object.keys(form).reduce((acc, curr, index) => {
                acc[curr] = emp_master_leave_data[curr];
                if (index === Object.keys(form).length - 1) {
                    acc[curr] = emp_master_leave_data[curr] || 0;
                }
                return acc;
            }, {});
        setForm(formWithExistingData);
    }


    // DELETE FUNCTIONALITY
    const handleClickDeleteButton = (emp_master_leave_data: any) => {
        setMasterFormVisible({ visible: false, text: 'delete' });
        setEnableConfirmDeleteModel(true);
        setForm({ employee_id: emp_master_leave_data.employee_id })
    }

    const okDeleteEmployeeData = (e: any) => {
        setEnableConfirmDeleteModel(false);
        addUpdateOrDeleteLeaveDataInMasterTable(e);
        setForm(getFormDefault());
    }

    const cancelDeleteEmployeeData = () => {
        setEnableConfirmDeleteModel(false);
        setForm(getFormDefault());
    }

    // ADD NEW COLUMN FUNCTIONALITY
    const addNewColumn = (e: any) => {
        setEnableAddNewYearModel(true);
    }

    const okAddNewYearColumn = (e: any) => {
        setEnableAddNewYearModel(false);
        handleCreateNewColumn(e);
    }

    const cancelAddNewYearColumn = () => {
        setEnableAddNewYearModel(false);
    }


    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (searchTimeOut) {
            clearTimeout(searchTimeOut);
        }
        const value = e.target.value;
        const ele: any = e.nativeEvent;
        const key: any = ele.data;
        const inputType = ele.inputType;

        if (
            key !== null &&
            inputType !== "deleteContentBackward" &&
            (value === "" || value?.length < 3)
        ) {
            setFilterParams((prev: any) => {
                return {
                    ...prev,
                    searchText: "",
                };
            });
            return;
        }
        setSearchTimeOut(
            setTimeout(
                () =>
                    setFilterParams((prev: any) => {
                        return {
                            ...prev,
                            searchText: value,
                        };
                    }),
                1200
            )
        );
    }


    const filteredData = useMemo(() => {
        if (!filterParams.searchText) {
            return tableData;
        }
        return tableData.filter((row) =>
            row.employee_name.toLowerCase().includes(filterParams.searchText.toLowerCase())
            ||
            row.designation.toLowerCase().includes(filterParams.searchText.toLowerCase())
            ||
            row.email.toLowerCase().includes(filterParams.searchText.toLowerCase())
        );
    }, [tableData, filterParams.searchText]);


    const getName = (tableData: any[]) => {
        return tableData.find((elem) => elem.employee_id === form.employee_id)?.employee_name;
    }


    useEffect(() => {
        const fetchData = async () => {
            await getFormDropdowns();
            await getLeaveReconcilliationMasterData();
        };
        fetchData();
    }, []);


    return (
        <>
            {
                error ? (
                    <React.Fragment>
                        <h2>{error.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{error.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (

                    <LoadingSpinner isLoading={isLoading}>
                        <Layout className="mainInnerLayout leaveReconciliationMaster">
                            <div className="innerHeader headerSingle">
                                <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                    <div className="ant-col ant-col-xl-24 flx justifyContentBetween alignItemsCenter">
                                        <div className='ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter'>
                                            <h3 style={{ width: '50%' }}>{Constants.LEAVE_RECONCILIATION_MASTER_SHEET}</h3>
                                            <Input
                                            placeholder={Constants.SEARCH_LABEL_MASTER_RECONCILIATION}
                                            disabled={isLoading || isTableLoading}
                                            allowClear
                                            onChange={handleChange}
                                            />
                                        </div>
                                        <div className='flx ant-col ant-col-xl-8' style={{ justifyContent: 'flex-end' }}>
                                            <button
                                                style={{ backgroundColor: `${(isTableLoading || isLoading) ? 'gray' : '#0062ff'}`, color: 'white' }}
                                                className='addUserBtn mr-10 pointer'
                                                onClick={handleClickAddNewData}
                                                disabled={isTableLoading}>
                                                <i className="da icon-add-user-o"></i>
                                                New Member
                                            </button>

                                            <button
                                                style={{ backgroundColor: `${(disableAddColumnButton || isTableLoading) ? 'gray' : '#0062ff'}`, color: 'white' }}
                                                className='addUserBtn pointer'
                                                onClick={addNewColumn}
                                                disabled={(disableAddColumnButton || isTableLoading)}
                                            >
                                                <i className="da icon-add-user-o"></i>
                                                New Year
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <LeaveReconcilliationMasterTable
                            getFormDefault={getFormDefault}
                            setForm={setForm}
                            handleClickDelete={handleClickDeleteButton}
                            isTableLoading={isTableLoading}
                            handleClickDropdownUpdate={handleClickUpdateButton}
                            handleClickInlineUpdate={handleClickUpdateButtonInline}
                            tableHeaders={tableHeaders}
                            tableData={filteredData}
                            form={form}
                            setTableData={setTableData}
                            addUpdateOrDeleteLeaveDataInMasterTable={addUpdateOrDeleteLeaveDataInMasterTable}
                            />

                            <Modal
                            closable={false}
                            width={350}
                            title={false}
                            footer={false}
                            visible={enableConfirmDeleteModel}
                            className="confirmModal">
                                <div className="modalHeader modalHeaderCenter">
                                    {
                                        Constants.DELETE_USER_CONFIRMATION_MESSAGE_OPEN
                                    }
                                    {" "}
                                    <span style={{ color: "red" }}>Delete</span>{" "}
                                    {
                                        Constants.DELETE_USER_CONFIRMATION_MESSAGE_CLOSE.replace("fullname", getName(tableData))
                                    }
                                </div>
                                <div className="modalCustomFooter">
                                    <Button
                                        className="linkBtn linkBtn"
                                        onClick={cancelDeleteEmployeeData}
                                    >
                                        {Constants.CANCEL}
                                    </Button>
                                    <Button
                                        className="btnOk"
                                        onClick={okDeleteEmployeeData}
                                    >
                                        {Constants.OK}
                                    </Button>
                                </div>
                            </Modal>

                            <Modal
                                closable={false}
                                width={350}
                                title={false}
                                footer={false}
                                visible={enableAddNewColumnModel}
                                className="confirmModal">
                                <div className="modalHeader modalHeaderCenter">
                                    Want to add a new year column?
                                </div>
                                <div className="modalCustomFooter">
                                    <Button
                                        className="linkBtn linkBtn"
                                        onClick={cancelAddNewYearColumn}
                                    >
                                        {Constants.CANCEL}
                                    </Button>
                                    <Button
                                        className="btnOk"
                                        onClick={okAddNewYearColumn}
                                    >
                                        {Constants.OK}
                                    </Button>
                                </div>
                            </Modal>


                            <Modal
                                closable={false}
                                title={false}
                                footer={false}
                                visible={masterFormVisible.visible}
                                centered
                                className="editModal"
                                width={600}
                            >

                                <LeaveReconcilliationMasterForm
                                    filteredData={filteredData}
                                    handleClickEditUserInfoCancel={handleClickEditUserInfoCancel}
                                    masterFormVisible={masterFormVisible}
                                    addUpdateOrDeleteLeaveDataInMasterTable={addUpdateOrDeleteLeaveDataInMasterTable}
                                    dropdownData={dropdownData}
                                    disableAddColumnButton={disableAddColumnButton}
                                    form={form}
                                    setForm={setForm}
                                    setError={setError}
                                    signOutWithMessage={signOutWithMessage}
                                />
                            </Modal>
                        </Layout>
                    </LoadingSpinner>
                )
            }
        </>
    )
}

export default LeaveReconcilliationMaster;
