import { Button, Input, Layout, message, Modal, Select } from "antd";
import { usePageTitle } from "hooks";
import { FixedColumnDirection } from "models/enums/table";
import { IFilterData } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { JoinersListOnboardingService } from "services";
import { auth } from "utils/auth/google.security";
import FilterDrawer from "utils/commonFilter/filterDrawer/FilterDrawer";
import {
  addToolTip,
  getEncodedData,
  stringifyArrayValues,
} from "utils/commonFunction";
import {
  getCurrentDate,
  getCustomDate,
  getCustomDateOrNull,
} from "utils/formatTimePeriod";
import LoadingSpinner from "utils/sharedComponents/LoadingSpinner";
import CommonReportTable from "utils/table/CommonReportTable";
import JoineeForm2 from "./JoineeForm2";
import { Constants } from "../constants";
import moment from "moment";
import OnboardingModalHeader from "./OnboardingModalHeader";
import { useApiServices } from "hooks/useApiServices";

export const joinersHeaders = [
  {
    title: "",
    dataIndex: "joinee_id",
    thClassName: "text-left",
    width: 180,
    fixed: FixedColumnDirection.left,
    dataRender: (
      data: any,
      indexVal: number,
      setConfirmJoin: Function,
      edit: any,
      setOnboardingStatus: Function
    ) => {
      return (
        <div
          className="tblActions"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <div onClick={edit} style={{ margin: "0 5px" }}>
            <i className="da icon-pencil-o-dark" />
          </div>
          <React.Fragment>
            {data?.is_confirmed === true &&
              data?.onboarding_status !== true && (
                <Select
                  className="selectDropdownForTbl"
                  placeholder="Select"
                  options={[
                    { label: "Joined", value: true },
                    { label: "Not Joined", value: false },
                    { label: "Re-send Email", value: 'resend' }
                  ]}
                  onChange={(value) => setOnboardingStatus(value, indexVal)}
                  value={"Confirmed"}
                  style={{
                    width: 130,
                    textAlign: "center",
                    marginRight: "0px",
                  }}
                ></Select>
              )}
            {data?.is_confirmed === true &&
              data?.onboarding_status === true && (
                <div
                  className="text-success"
                  style={{
                    textAlign: "center",
                    padding: "3px 12px",
                    width: 130,
                  }}
                >
                  Confirmed
                </div>
              )}
            {data?.is_confirmed === false && (
              <div
                className="text-danger"
                style={{
                  width: 130,
                  textAlign: "center",
                  marginRight: "0px",
                }}
              >
                Joining Declined
              </div>
            )}
            {data?.is_confirmed === null && (
              <Select
                className="selectDropdownForTbl"
                placeholder="Select"
                options={[
                  { label: "Joining", value: true },
                  { label: "Not Joining", value: false },
                ]}
                onChange={(value) => setConfirmJoin(value, indexVal)}
                value={data.is_confirmed ?? "Confirm"}
                style={{ width: 130, textAlign: "center", marginRight: "0px" }}
              ></Select>
            )}
          </React.Fragment>
        </div>
      );
    },
  },
  {
    title: "",
    titleRender: () => (
      <div>
        Onboarding
        <br />
        Status
      </div>
    ),
    dataIndex: "onboarding_status",
    width: 100,
    fixed: FixedColumnDirection.left,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      return (
        <div
          className={`venueNameData text-capitalize ${data ?? false
            ? "text-success"
            : all.data.singleData?.is_confirmed === false
              ? "text-danger"
              : "text-warning"
            } `}
        >
          {data ?? false
            ? "Completed"
            : all.data.singleData?.is_confirmed === false
              ? "Cancelled"
              : "Pending"}
        </div>
      );
    },
  },
  {
    title: "Name",
    dataIndex: "first_name",
    width: 290,
    thClassName: "text-left",
    fixed: FixedColumnDirection.left,
    dataRender: (data: string, all: any) => {
      const last_name = all.data.singleData?.last_name;
      const office_email_id = all.data.singleData?.official_email_id;
      return (
        <div className="venueNameData">
          <div className="text-capitalize">
            {(data + " " + (last_name ?? "")).length > 37
              ? addToolTip(
                data + " " + (last_name ?? ""),
                "right",
                "default",
                37
              )
              : data + " " + (last_name ?? "")}
          </div>
          {office_email_id?.length > 37
            ? addToolTip(office_email_id, "right", "default", 37)
            : office_email_id}
        </div>
      );
    },
  },
  {
    title: "Contact Info",
    dataIndex: "mobile_number",
    width: 250,
    thClassName: "text-left",
    fixed: FixedColumnDirection.left,
    dataRender: (data: string, all: any) => {
      const personal_email_id = all.data.singleData?.personal_email_id;
      return (
        <div className="venueNameData">
          <div className="text-capitalize">{data}</div>
          {personal_email_id?.length > 30
            ? addToolTip(personal_email_id, "right", "default", 30)
            : personal_email_id}
        </div>
      );
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    width: 150,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      return <div className="venueNameData text-capitalize">{data}</div>;
    },
  },
  {
    title: "Date of joining",
    dataIndex: "date_of_joining",
    width: 180,
    canSort: true,
    thClassName: "text-left",
    dataRender: (data: any, all: any) => {
      return (
        <div className="venueNameData text-capitalize">
          {getCustomDate(data, "MMM DD, YYYY")}
        </div>
      );
    },
  },
  {
    title: "Designation",
    dataIndex: "designation",
    width: 250,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      const department = all.data.singleData?.department;
      return (
        <div className="venueNameData text-capitalize">
          <div>{data}</div>
          <div>{department}</div>
        </div>
      );
    },
  },
  {
    title: "Working Mode",
    dataIndex: "working_mode",
    width: 150,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      return <div className="venueNameData text-capitalize">{data}</div>;
    },
  },
  {
    title: "Candidate Source",
    dataIndex: "source",
    width: 200,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      return <div className="venueNameData text-capitalize">{data}</div>;
    },
  },
  {
    title: "",
    titleRender: () => (
      <div>
        Confidential Agreement
        <br />
        Signed on
      </div>
    ),
    dataIndex: "agreement_signed_on",
    width: 200,
    thClassName: "text-left",
    dataRender: (data: any, all: any) => {
      return (
        <div className="venueNameData text-capitalize">
          {getCustomDateOrNull(data, "MMM DD, YYYY")}
        </div>
      );
    },
  },
  {
    title: "",
    titleRender: () => (
      <div>
        Appointment Letter
        <br />
        Signed on
      </div>
    ),
    dataIndex: "appointment_letter_signed_on",
    width: 200,
    thClassName: "text-left",
    dataRender: (data: any, all: any) => {
      return (
        <div className="venueNameData text-capitalize">
          {getCustomDateOrNull(data, "MMM DD, YYYY")}
        </div>
      );
    },
  },
  {
    title: "Employee Type",
    dataIndex: "employment_type",
    width: 150,
    thClassName: "text-left",
    dataRender: (data: string, all: any) => {
      return <div className="venueNameData text-capitalize">{data}</div>;
    },
  },
];

export default function OnboardingPage() {
  usePageTitle(Constants.ONBOARDING_TITLE);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [joinersData, setJoinersData] = useState<any>([]);
  const [maxImgSize, setMaxImgSize] = useState<number | null>(null);
  const [form, setForm] = useState<any>({
    personal_email: "",
    last_name: "",
    office_email: "",
    first_name: "",
    mobile_no: "",
    working_mode: 3,
    reporting_manager: "",
    send_welcome_ecard: true,
  });
  const [sortOptions, setSortOptions] = useState<any>({
    dataIndex: "date_of_joining",
    order: "desc",
  });
  const [dropdownData, setDropdownData] = useState<any>({});
  const [error, setError] = useState<any>(null);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showOnboardingStatusChange, setShowOnboardingStatusChange] =
    useState(false);
  const [joinerInfoState, setJoinerInfoState] = useState<any>({});
  const [confirmationState, setConfirmationState] = useState<any>({});
  const [onboardingState, setOnboardingState] = useState<any>({});
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [filterParams, setFilterParams] = useState<any>({
    onboarding_status: false,
  });
  const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
  const [accessOption, setAccessOption] = useState<string | null>(null);

  const [showResendSubmitDocumentEmailModel, setShowResendSubmitDocumentEmailModel] = useState(false);
  const [selectedJoinee, setSelectedJoinee] = useState<{joineeId: Number} | null>(null);
  const { fetchData, updateData, isLoading: isPageLoding, isError, data: candidateData } = useApiServices()

  const signOutWithMessage = (value: string) => {
    auth.signOut();
    window.localStorage.removeItem("emailId");
    window.localStorage.removeItem("displayName");
    window.localStorage.removeItem("expirationTime");
    window.localStorage.removeItem("authToken");
    const msg = getEncodedData(value);
    return history.push(`/login?msg=${msg}`);
  };
  const editUserInfoCancel = () => {
    setIsModalVisible4(false);
    setForm({
      personal_email: "",
      last_name: "",
      office_email: "",
      first_name: "",
      mobile_no: "",
      working_mode: 3,
      reporting_manager: "",
      welcome_kit: "",
      source: "",
      send_welcome_ecard: true,
    });
    setAccessOption(null);
  };

  const setConfirmJoin = (value: any, index?: number) => {
    if (index !== undefined && index >= 0) {
      const joinee = joinersData[index];
      setConfirmationState({ actionName: value, joinee_id: joinee?.joinee_id });
      setShowDeleteConfirm(true);
    }
  };

  const setOnboardingStatus = (bool: any, val: number) => {
    
    if(bool === 'resend'){
      const joinee = joinersData[val];
      setSelectedJoinee({joineeId: joinee?.joinee_id})
      setJoinerInfoState(joinee);
      setShowResendSubmitDocumentEmailModel(true);
    }
    else if (val !== undefined && val >= 0) {
      const joinee = joinersData[val];
      setOnboardingState({
        onboarding_status: bool,
        joinee_id: joinee?.joinee_id,
      });
      setJoinerInfoState(joinee);
      setShowOnboardingStatusChange(true);
    }
  };

  const changeOnboardingStatus = async () => {
    setIsUpdatingStatus(true);
    setShowOnboardingStatusChange(false);
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.STATUS_UPDATING_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      if (onboardingState.onboarding_status === false) {
        await new JoinersListOnboardingService().updateJoineeStatus({
          ...onboardingState,
          actionName: false,
          type: "is_confirmed",
        });
        const joinee = joinersData.find(
          (joiner: any) => joiner.joinee_id === onboardingState.joinee_id
        );
        await new JoinersListOnboardingService().revokeNotJoinedAccess({
          employee_id: joinee.employee_id,
          official_email_id: joinee.official_email_id,
          name: `${joinee?.first_name} ${joinee?.last_name}`,
          date_of_joining: getCurrentDate("MMM DD, YYYY"),
        });
      } else {
        await new JoinersListOnboardingService().updateJoineeStatus({
          ...onboardingState,
          type: "onboarding_status",
        });
        if(joinerInfoState?.send_welcome_ecard){

          await new JoinersListOnboardingService().sendWelcomeMail({
            ...joinerInfoState,
            name: `${joinerInfoState?.first_name} ${joinerInfoState?.last_name}`,
            date_of_joining: getCustomDate(
              joinerInfoState?.date_of_joining,
              "MMM DD, YYYY"
            ),
          });
      }
        await new JoinersListOnboardingService().sendNextStepMail({
          name: `${joinerInfoState?.first_name} ${joinerInfoState?.last_name}`,
          official_email_id: joinerInfoState?.official_email_id,
          welcome_kit: joinerInfoState?.welcome_kit ?? "",
        });
      }
      message.success({
        content: Constants.STATUS_UPDATED_MESSAGE,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
      setIsTableLoading(true);
      await getJoinersList(filterParams);
      setIsTableLoading(false);
      setIsUpdatingStatus(false);
    } catch (error: any) {
      setIsTableLoading(false);
      setIsUpdatingStatus(false);
      setShowOnboardingStatusChange(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.STATUS_UPDATE_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };
 

  const changeStatus = async () => {
    setIsUpdatingStatus(true);
    setShowDeleteConfirm(false);
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.STATUS_UPDATING_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOnboardingService().updateJoineeStatus({
        ...confirmationState,
        type: "is_confirmed",
      });
      message.success({
        content: Constants.STATUS_UPDATED_MESSAGE,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
      setIsTableLoading(true);
      await getJoinersList(filterParams);
      setIsTableLoading(false);
      setIsUpdatingStatus(false);
    } catch (error: any) {
      setIsUpdatingStatus(false);
      setShowDeleteConfirm(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.STATUS_UPDATE_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };

  const dayDiff = (endDate: string) => {
    const end_date = moment(endDate);
    return end_date.diff(moment(), 'days');
  }

  const canGiveImmediateAccessOfAllCredentialsToJoinee = (joinee_id: number, date_of_joining: string, is_confirmed: boolean) => {
    if (joinee_id && is_confirmed) {
      const duration = dayDiff(date_of_joining);
      return Boolean((duration >= 0) && (duration <= 3));
    }
    return false
  }

  const onChangeAccessCredentials = (value: string) => {
    setAccessOption(value)
    if (value) {
      if (value === 'generate-all-access') {
        giveImmediateAccessOfAllCredentialsToJoinee(form.joinee_id)
      } else if (value === 'invite-to-zoho') {
        inviteJoineeToZoho(form.joinee_id)
      } else if (value === 'invite-to-jira') {
        inviteJoineeToJira(form.joinee_id)
      }
    }
  }

  const giveImmediateAccessOfAllCredentialsToJoinee = async (joinee_id: number) => {
    setIsUpdatingStatus(true);
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.ACCESS_CREDENTIALS_CREATION_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOnboardingService().giveJoineeAccess({
        joinee_id
      });
      message.success({
        content: Constants.ACCESS_CREDENTIALS_CREATION_SUCCESS,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.ACCESS_CREDENTIALS_CREATION_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };


  const inviteJoineeToZoho = async (joinee_id: number) => {
    setIsUpdatingStatus(true);
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.ACCESS_CREDENTIALS_CREATION_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOnboardingService().inviteJoineeZoho({
        joinee_id
      });
      message.success({
        content: Constants.ACCESS_CREDENTIALS_CREATION_SUCCESS,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.ACCESS_CREDENTIALS_CREATION_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };

  const inviteJoineeToJira = async (joinee_id: number) => {
    setIsUpdatingStatus(true);
    const saveJoineeStatusLoadingKey = "updateJoineeStatus";
    try {
      message.loading({
        content: Constants.ACCESS_CREDENTIALS_CREATION_MESSAGE,
        duration: 0,
        key: saveJoineeStatusLoadingKey,
        className: "toastMsg loadingMsg",
      });
      await new JoinersListOnboardingService().inviteJoineeJira({
        joinee_id
      });
      message.success({
        content: Constants.ACCESS_CREDENTIALS_CREATION_SUCCESS,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedSuccess",
      });
    } catch (error: any) {
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      message.error({
        content: Constants.ACCESS_CREDENTIALS_CREATION_FAILED,
        key: saveJoineeStatusLoadingKey,
        duration: 5,
        className: "toastMsg savedError",
      });
    }
  };

  const showEditUserInfoModal = async (title: string, index?: number) => {
    setModalTitle(title);
    if (index !== undefined && index >= 0) {
      const data = joinersData[index];
      let image = data?.profile_image;

      let profile_image = null;

      if (image !== null) {
        const getProfileImageLoadingKey = 'getProfileImageUrl'
        try {
  
          const payload = { "profile_image": image }

          const response: any =
           await new JoinersListOnboardingService().getProfileImageUrl(payload);
           if (response.data.msg_id === 1) {
            profile_image = response.data['image_url'];
            
          }
        } catch (error: any) {
          
          setError({
            ...error,
            refreshAction: getProfileImageLoadingKey,
          });
          if (error.code === 401) {
            signOutWithMessage("You are not authorized");
          }
        }
        
      }
      setForm({
        joinee_id: data?.joinee_id,
        first_name: data?.first_name,
        office_email: data?.official_email_id,
        send_welcome_ecard: data?.send_welcome_ecard,
        about_candidate: data?.about_candidate,
        profile_image: data?.profile_image,
        profile_image_url: profile_image,
        personal_email: data?.personal_email_id,
        last_name: data?.last_name,
        location: data?.location_id,
        designation: data?.designation_id,
        department: data?.department_id,
        remove_ip_restrict: data?.remove_ip_restrict,
        invite_to_zoho_vault: data?.invite_to_zoho_vault,
        candidate_source: data?.candidate_source_id,
        employee_type: data?.employment_type_id,
        mobile_no: data?.mobile_number,
        working_mode: data?.working_mode_id,
        date_of_joining: data?.date_of_joining,
        date_of_onboarding: data?.date_of_onboarding,
        date_of_probation: data?.date_of_probation,
        agreement_signed_on: data?.agreement_signed_on,
        appointment_letter_signed_on: data?.appointment_letter_signed_on,
        reporting_manager: data?.reporting_manager,
        assigned_project: data?.assigned_project,
        is_confirmed: data?.is_confirmed,
        onboarding_status: data?.onboarding_status,
        welcome_kit: data?.welcome_kit,
        confidential_agreement: data?.confidential_agreement,
        appointment_letter: data?.appointment_letter
      });
    }
    setIsModalVisible4(true);
  };

  const getJoinersList = async (params?: any) => {
    setIsTableLoading(true);
    try {
      const response: any =
        await new JoinersListOnboardingService().getJoinersList({
          sort_column: sortOptions.dataIndex,
          sort_order: sortOptions.order,
          ...stringifyArrayValues(params),
          // ...params
        });
      setJoinersData(response.data?.joinee_info);
      setIsTableLoading(false);
      setError(null);
    } catch (error: any) {
      setIsTableLoading(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      setError({
        ...error,
        refreshAction: getJoinersList,
      });
    }
  };

  // get new employee form datas
  const getFormDropdowns = async () => {
    setIsLoading(true);
    try {
      const response: any =
        await new JoinersListOnboardingService().getJoinersFormDropdowns({});

        setMaxImgSize(response.data?.MAX_IMG_SIZE);

      setDropdownData((prev: any) => { 

        return {
          ...prev,
          ...response.data?.dropdown_info,
          onboarding_status: [
            { label: "All", value: null },
            { label: "Completed", value: true },
            { label: "Pending", value: false },
            { label: "Cancelled", value: "cancelled" },
          ],
        };
      });
      setIsLoading(false);
      await getJoinersList(filterParams);
    } catch (error: any) {
      setIsLoading(false);
      if (error.code === 401) {
        signOutWithMessage("You are not authorized");
      }
      setError({
        ...error,
        refreshAction: getJoinersList,
      });
    }
  };

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    const value = e.target.value;
    const ele: any = e.nativeEvent;
    const key: any = ele.data;
    const inputType = ele.inputType;
    if (
      key !== null &&
      inputType !== "deleteContentBackward" &&
      (value === "" || value?.length < 3)
    ) {
      if (filterParams?.search) {
        setFilterParams((prev: any) => {
          return {
            ...prev,
            search: "",
          };
        });
      }
      return;
    }
    setSearchTimeOut(
      setTimeout(
        () =>
          setFilterParams((prev: any) => {
            return {
              ...prev,
              search: value,
            };
          }),
        1200
      )
    );
  }

  const applyFilterHandler = (filterOptions: IFilterData) => {
    setFilterParams((prev: any) => {
      return {
        ...prev,
        ...filterOptions,
      };
    });
  };

  const resetFilterHandler = (filterOptions: IFilterData | null) => {
    setFilterParams((prev: any) => {
      return {
        ...prev,
        ...filterOptions,
      };
    });
  };


  const resendSubmitOnboardingDocumentsEmail = async () => {
    setIsUpdatingStatus(true);
    setShowResendSubmitDocumentEmailModel(false);
    await updateData('RESEND_UPLOAD_ONBOARDING_DOCUMENT_EMAIL', selectedJoinee)    
  };
  
  useEffect(() => {
    getFormDropdowns();
  }, []);

  useEffect(() => {
    if (isLoading === false) getJoinersList(filterParams);
  }, [filterParams]);

  useEffect(() => {
    return () => {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
    };
  }, [searchTimeOut]);

  return (
    <LoadingSpinner isLoading={isLoading ? isLoading : false}>
      <Layout className="mainInnerLayout">
        {error ? (
          <React.Fragment>
            <h2>{error.code} Error</h2>
            <div className="errorContent">
              <div className="errorContentInner">
                <span className="error-img"></span>
                <div>
                  <h3>{error.message}</h3>
                  <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>{Constants.ONBOARDING}</h2>
            <div className="innerHeader">
              <div className="ant-row alignItemsCenter">
                <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter">
                  <h3 style={{ width: "50%" }}>
                    {Constants.NEW_TEAM_MEMBERS_LIST}
                  </h3>
                  <Input
                    placeholder={Constants.SEARCH_LABEL}
                    allowClear
                    onChange={handleChange}
                  />
                </div>
                <div className="ant-col ant-col-xl-8">
                  <div className="innerHeaderRight flx alignItemsFlexEnd">
                    <button
                      className="addUserBtn mr-10"
                      type="button"
                      onClick={() =>
                        showEditUserInfoModal(Constants.ADD_NEW_MEMBER_INFO)
                      }
                    >
                      <i className="da icon-add-user-o"></i>
                      {Constants.NEW_MEMBER}
                    </button>
                    <FilterDrawer
                      disabled={false}
                      drawerInputs={[
                        {
                          type: "singleSelect",
                          label: "Onboarding Status",
                          keyName: "onboarding_status",
                          options: dropdownData?.onboarding_status,
                          show: true,
                          defaultValue: false,
                        },
                        {
                          type: "multiSelect",
                          label: "Department",
                          keyName: "department_info",
                          options: dropdownData?.department_info,
                          show: true,
                        },
                        {
                          type: "multiSelect",
                          label: "Designation",
                          keyName: "designation_info",
                          options: dropdownData?.designation_info,
                          show: true,
                        },
                        {
                          type: "multiSelect",
                          label: "Working Mode",
                          keyName: "working_mode_info",
                          options: dropdownData?.working_mode_info,
                          show: true,
                        },
                        {
                          type: "multiSelect",
                          label: "Location",
                          keyName: "locations_info",
                          options: dropdownData?.locations_info,
                          show: true,
                        },
                        {
                          type: "multiSelect",
                          label: "Candidate Source",
                          keyName: "candidate_info",
                          options: dropdownData?.candidate_info,
                          show: true,
                        },
                      ]}
                      onApplyFilter={applyFilterHandler}
                      onResetFilter={resetFilterHandler}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="customTbl userManagementTbl scroll-y tblStickyTHead">
                <CommonReportTable
                  tableClass="customTbl scroll-y main-table"
                  tableHeight={{ maxHeight: "calc(100vh - 218px)" }}
                  onSort={(sortIndex: string, sortOrder: "asc" | "desc") => {
                    getJoinersList({
                      sort_column: sortIndex,
                      sort_order: sortOrder,
                      ...filterParams,
                    });
                    setSortOptions((prev: any) => {
                      return {
                        dataIndex: "date_of_joining",
                        order: prev?.order === "desc" ? "asc" : "desc",
                      };
                    });
                  }}
                  sortField={sortOptions}
                  data={joinersData}
                  otherData={joinersData}
                  headers={joinersHeaders}
                  loading={isTableLoading}
                  setConfirmJoin={setConfirmJoin}
                  showEditUserInfoModal={showEditUserInfoModal}
                  setOnboardingStatus={setOnboardingStatus}
                />
              </div>
            </div>

            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={showDeleteConfirm}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {Constants.CONFIRM_JOINER_MESSAGE_OPEN}{" "}
                <span
                  className={
                    confirmationState.actionName
                      ? "text-success"
                      : "text-danger"
                  }
                >{`${confirmationState.actionName ? "Joining" : "Not Joining"
                  }`}</span>{" "}
                {Constants.CONFIRM_JOINER_MESSAGE_CLOSE}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setShowDeleteConfirm(false);
                  }}
                  disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  disabled={isUpdatingStatus}
                  onClick={() => changeStatus()}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>

            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={showOnboardingStatusChange}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {Constants.UPDATE_ONBOARDING_STATUS_MESSAGE_OPEN}{" "}
                {onboardingState.onboarding_status ? (
                  <span className={"text-success"}>Complete</span>
                ) : (
                  <span className={"text-danger"}>Cancel</span>
                )}{" "}
                {Constants.UPDATE_ONBOARDING_STATUS_MESSAGE_CLOSE}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setShowOnboardingStatusChange(false);
                  }}
                  disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  disabled={isUpdatingStatus}
                  onClick={() => changeOnboardingStatus()}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>



            <Modal
              closable={false}
              width={350}
              title={false}
              footer={false}
              visible={showResendSubmitDocumentEmailModel}
              className="confirmModal"
            >
              <div className="modalHeader modalHeaderCenter">
                {Constants.RESEND_UPLOAD_DOCUMENT_MESSAGE_OPEN}{" "}
                {
                  <span className={"text-danger"}>Re-send</span>
                }{" "}
                {Constants.RESEND_UPLOAD_DOCUMENT_MESSAGE_CLOSE}
              </div>
              <div className="modalCustomFooter">
                <Button
                  className="linkBtn linkBtn"
                  onClick={() => {
                    setShowResendSubmitDocumentEmailModel(false);
                  }}
                  disabled={isUpdatingStatus}
                >
                  {Constants.CANCEL}
                </Button>
                <Button
                  className="btnOk"
                  disabled={isUpdatingStatus}
                  onClick={() => resendSubmitOnboardingDocumentsEmail()}
                >
                  {Constants.OK}
                </Button>
              </div>
            </Modal>




            <Modal
              closable={false}
              width={870}
              title={false}
              footer={false}
              visible={isModalVisible4}
              centered
              className="editModal"
            >
              <OnboardingModalHeader modalTitle={modalTitle} canGiveImmediateAccessOfAllCredentialsToJoinee={canGiveImmediateAccessOfAllCredentialsToJoinee} form={form} accessOption={accessOption} onChangeAccessCredentials={onChangeAccessCredentials} />
              <JoineeForm2
                modalTitle={modalTitle}
                filterParams={filterParams}
                dropdownData={dropdownData}
                editUserInfoCancel={editUserInfoCancel}
                form={form}
                setForm={setForm}
                getJoinersList={getJoinersList}
                setIsLoading={setIsTableLoading}
                setError={setError}
                signOutWithMessage={signOutWithMessage}
                setDropdownData={setDropdownData}
                maxImgSize={maxImgSize}
              />
            </Modal>
          </React.Fragment>
        )}
      </Layout>
    </LoadingSpinner>
  );
}
