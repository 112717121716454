import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Calendar, Button } from 'antd';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { IRegularizeData, IinTimeAfterCheck } from 'models/interfaces/Regularize/regularizeOptions.model';
import { Constants } from 'views/constants';



interface CalanderComponentInterface {
  currentMonth: moment.Moment,
  setCurrentMonth: React.Dispatch<React.SetStateAction<moment.Moment>>,
  isTableLoading: boolean;
  regularisationData: IRegularizeData;
  setRegularisationData: React.Dispatch<React.SetStateAction<IRegularizeData>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  eventArray: any
  setInTimeAfterOutTime: React.Dispatch<React.SetStateAction<IinTimeAfterCheck>>
}

export const CalanderComponent: React.FC<CalanderComponentInterface> =
  ({ currentMonth, setCurrentMonth, isTableLoading, regularisationData, setRegularisationData, setOpen, eventArray, setInTimeAfterOutTime }) => {

    const RegularizeAttendance = (formattedDate: any, eventsOnDate: any) => {
      setInTimeAfterOutTime({ bool: false, from: '', msg: '' });
      const { todayEvents, id } = eventsOnDate;
      let inTime = null;
      let outTime = null;
      let duration = 'invalid time';

      if (moment(todayEvents[0].date).local().format('HH:mm') !== '00:00') {  //  not absent case
        inTime = moment(todayEvents[0].date).local();
      }
      if (moment(todayEvents[1].date).local().format('HH:mm') !== '00:00') {  //  not absent case
        outTime = moment(todayEvents[1].date).local();
      }
      if (moment(todayEvents[2].date).local().format('HH:mm') !== '00:00') {
        duration = moment(todayEvents[2].date).local().format('HH:mm');
      }

      setOpen(true);
      setRegularisationData({ ...regularisationData, inTime, outTime, duration, date: moment(formattedDate), id })
    }
    

    const dateCellRender = (value: { format: (arg0: string) => any }) => {
      const formattedDate = value.format('YYYY-MM-DD');
      const eventsOnDate = eventArray[formattedDate];
      const weekDay = moment(formattedDate).weekday()
      const weeklyWfh = (weekDay === 2 || weekDay === 5)


      if (eventsOnDate) {
        const { regularisation_status, todayEvents, wasAbsent, wfhStatus, holidayStatus } = eventsOnDate;

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: `${!regularisation_status && !wfhStatus && !holidayStatus ? 'space-between' : 'center'}`, height: '94%' }}>

            {
              wfhStatus && !todayEvents && <div style={{ fontSize: '14px', textTransform: 'capitalize' }} className='status-accepted'>WFH</div>
            }

            {
              holidayStatus && <div style={{ fontSize: '14px', textTransform: 'capitalize' }} className='status-accepted'>Holiday</div>
            }

            {
              !wfhStatus && wasAbsent && !weeklyWfh && !regularisation_status && <div style={{ fontSize: '14px', color: '#ca1640', textTransform: 'capitalize' }}>absent</div>
            }

            {
              !wfhStatus && wasAbsent && weeklyWfh && <div style={{ fontSize: '14px', color: '#ca1640', textTransform: 'capitalize' }}></div>
            }

            {
              todayEvents &&
              (<>
                {
                  !wasAbsent &&
                  <ul className="events" style={{ marginBottom: '10px' }}>
                    {
                      todayEvents?.map((event: any, index: number) => {
                        return (
                          <li style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }} key={event?.title}>
                            <div>
                              {event?.title}
                              <span style={{ marginLeft: '.5rem' }}>{event?.time}</span>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                }

                {
                  regularisation_status ?
                    (<button className={`attendence-status 
                    ${regularisation_status === 'pending' ?
                      'status-pending'
                      :
                      (['accepted', 'approved'].includes(regularisation_status)) ?
                        'status-accepted'
                        :
                        'status-rejected'}`}>{regularisation_status}
                  </button>)
                :
                  (<Button
                    onClick={() => RegularizeAttendance(value, eventsOnDate)}
                    className='calendar-button-style'
                    style={{ padding: '2px 25px' }}>
                    Regularize
                  </Button>)
                }
              </>)
            }
          </div>
        );
      }
    };


    const handlePanelChange = (value: React.SetStateAction<moment.Moment>, mode: any) => {
      setCurrentMonth(value);
    };

    const handlePrevMonth = () => {
      setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
    };

    const handleNextMonth = () => {
      setCurrentMonth(currentMonth.clone().add(1, 'month'));
    };

    const disabledDate = (date: moment.Moment) => {
      if (date.isSame(new Date(), 'day')) return true;  // date day
      if (date.isAfter(moment(), 'day')) return true;  //future dates
      return false;
    };

    const headerRender: any = (config: any) => {
      const formattedMonth = config.value.format('MMMM YYYY');
      return (
        <div className="innerHeader headerSingle dailyAttendanceCalendarHeader">
          <div className="ant-row alignItemsCenter flx justifyContentBetween">
            <div className="ant-col ant-col-xl-10 flx justifyContentBetween alignItemsCenter">
              <h3 style={{ marginBottom: '0', fontSize: '1.3rem' }} >{Constants.BIOMETRIC_DAILY_ATTENDANCE}</h3>
            </div>


            <div className='flx ant-col ant-col-xl-12' style={{ justifyContent: 'flex-end' }}>

                <div className='monthTxt mr-10'>{formattedMonth}</div>

                <div className='calendarBtnWrap '>
                  <Button onClick={handlePrevMonth}
                    className='addUserBtn calendarBtn mr-10'>
                    <LeftOutlined className='calander-icons' />
                  </Button>
                  <Button onClick={handleNextMonth}
                    className='addUserBtn calendarBtn'
                    disabled={
                      config.value.month() === moment().month()
                      &&
                      config.value.year() === moment().year()
                    }
                  >
                    <RightOutlined className='calander-icons' />
                  </Button>
                </div>

              </div>
            </div>
          </div>
      );
    };


    useEffect(() => {
      moment.updateLocale('en', {
        weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      });
    })

    return (
      <LoadingSpinner isLoading={isTableLoading}>
        <Calendar
          dateCellRender={dateCellRender}
          headerRender={headerRender}
          mode="month"
          value={currentMonth}
          onPanelChange={handlePanelChange}
          disabledDate={disabledDate}
          style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}
        />
      </LoadingSpinner>
    );
  };

export default CalanderComponent;