import { usePageTitle } from 'hooks';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Constants } from '../constants';
import LoadingSpinner from 'utils/sharedComponents/LoadingSpinner';
import { useApiServices } from 'hooks/useApiServices';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import Checkbox from 'antd/es/checkbox';
import { useRbac } from 'features/rbac/rbac';
import RejectDrawer from 'utils/regularizeDrawer/RejectDrawer';
import NoDataFound from 'views/NoDataFound';
import moment from 'moment';
import EditAndApproveDrawer from 'utils/regularizeDrawer/EditAndApproveDrawer';
import { getFormattedDate, getIconClass, getSortArray, getWeekDays, getWeekdayString } from 'utils/commonFunction';
import useScrollEffect from 'hooks/useScrollEffect';


const tableDataStyle = {
    select: {
        minWidth: '200px',
        textAlign: 'center',
    },
    name: {
        left: '200px',
        minWidth: '250px',
        textAlign: 'left'
    },
    start_date: {
        minWidth: '250px',
        textAlign: 'left'
    },
    end_date: {
        minWidth: '250px',
        textAlign: 'left'
    },
    days: {
        minWidth: '250px',
        textAlign: 'left'
    },
    reason: {
        minWidth: '400px',
        textAlign: 'left'
    },
    task: {
        minWidth: '250px',
        textAlign: 'center'
    }
}

const WfhList = () => {
    usePageTitle(Constants.WFH_LIST_TITLE);
    const [rejectReason, setRejectReason] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const tableScrollableElement = useScrollEffect()
    const [editAndApproveDrawerOpened, setEditAndApproveDrawerOpened] = useState<boolean>(false);

    const { isLoading, data, isError, fetchData, updateData } = useApiServices();
    const [sort, setSort] = useState<{ keyToSort: string, direction: string }>({ keyToSort: "name", direction: "asc" });
    const [tableData, setTableData] = useState<any[]>([]);


    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [currentSelected, setCurrentSelected] = useState<any>({});
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    const { name } = useRbac().userDetailsInfo.sub;
    const tableHeaders = useMemo(() => [
        'select',
        'name',
        'start_date',
        'end_date',
        'days',
        'reason',
        'task'
    ], []);

    const weekDaysToShow = useMemo(() => getWeekDays(currentSelected.start_date, currentSelected.end_date), [currentSelected.start_date, currentSelected.end_date])


    const structureData = useCallback((wfhRequests: any[]) => {
        if (!wfhRequests) return [];
        let updatedData = [];

        updatedData = wfhRequests.map((currData: any) => {
            let tblDataObj: any = { id: currData.id, createdOn: currData?.created_at?.split('T')[0] }
            tableHeaders.forEach((currHeader) => {
                if (currHeader === 'days') {
                    tblDataObj[currHeader] = getWeekdayString(currData['wfh_days'])
                }
                else if (currHeader === 'start_date') {
                    tblDataObj[currHeader] = currData['from_date'];
                }
                else if (currHeader === 'end_date') {
                    tblDataObj[currHeader] = currData['to_date'];
                }
                else
                    tblDataObj[currHeader] = currData[currHeader];
            })
            return tblDataObj;
        });

        return updatedData;
    }, [tableHeaders]);



    function handleSortClick(header: string) {
        let direction = 'desc';
        if (header === sort.keyToSort && sort.direction !== 'asc') {
            direction = 'asc';
        }

        setSort({
            keyToSort: header,
            direction
        });
    };

    const handleSelectAll = () => {
        if (selectedItems.length === tableData.length) {
            setSelectedItems([]);
            return;
        }
        const allIds = tableData.map((curr: any) => curr.id);
        setSelectedItems(allIds)
    }


    // Approve or reject work from home
    const handleApproveReject = async (task: string, selectedItems: number[], selectedObj?: any) => {

        const obj = {
            id: selectedItems,
            is_approved: task === 'approved' || false,
            updated_by: name
        }

        if (selectedObj?.rejectReason)
            obj['reject_reason'] = selectedObj?.rejectReason;

        if (selectedObj?.start_date) {
            obj['from_date'] = selectedObj?.start_date?.format('YYYY-MM-DD');
            obj['to_date'] = selectedObj?.end_date?.format('YYYY-MM-DD');
            obj['edit_reason'] = selectedObj?.edit_reason;
            obj['wfh_days'] = selectedObj?.days;
            cancelEditAndApprove();
        }

        if (task !== 'approved') {
            cancelReject();
        }

        await updateData('UPDATE_WFH_REQUEST_STATUS', obj);
        setSelectedItems([]);
        await fetchData('GET_WFH_REQUEST_LIST');
    }


    const setRejectId = (id: number) => {
        setSelectedItem(id);
        setOpen(true);
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement> | undefined) => {
        if (e?.target && e.target.value) setRejectReason(e?.target.value)
    }
    const cancelReject = () => {
        setRejectReason('');
        setOpen(false)
    }
    const cancelEditAndApprove = () => {
        setEditAndApproveDrawerOpened(false);
        setCurrentSelected({})
    }




    const handleSelect = (currData: any) => {
        setEditAndApproveDrawerOpened(true);

        const { start_date, end_date, days: prevDays, id, name, reason } = currData;

        let days = null;

        if (prevDays && prevDays !== '-') {
            const dayArray = prevDays?.split(', ').map((curr: string) => curr.trim());
            days = dayArray.map((curr: string) => (moment().day(curr).isoWeekday()));
        }

        const selected = {
            start_date: moment(start_date, 'YYYY-MM-DD'),
            end_date: moment(end_date, 'YYYY-MM-DD'),
            edit_reason: '',
            task: 'approve',
            days, id, name, reason
        }

        setCurrentSelected(selected);
    };


    const handleCheckboxSelect = (id: number, selectedItems: number[]) => {
        if (selectedItems.includes(id))
            setSelectedItems(selectedItems.filter((curr) => curr !== id))
        else
            setSelectedItems([...selectedItems, id])
    }



    useEffect(() => {
        if (data) {
            const updatedData = structureData(data?.wfh_requests);
            setTableData(updatedData);
        }
    }, [data, structureData]);


    useEffect(() => {
        fetchData('GET_WFH_REQUEST_LIST');
    }, [fetchData]);



    return (
        <>
            {
                isError ?
                    <React.Fragment>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <Layout className="mainInnerLayout wfhList scroll-y tblStickyTHead" ref={tableScrollableElement}>
                        <div className="innerHeader" style={{ 'marginBottom': '18px', paddingTop: '20px' }}>
                            <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                <div className="ant-col ant-col-xl-24 flx justifyContentBetween alignItemsCenter">
                                    <h3>{Constants.WFH_LIST}</h3>
                                    <div className='flx ant-col ant-col-xl-8' style={{ justifyContent: 'flex-end' }}>
                                        {
                                            <div style={{ display: 'flex', gap: '1rem' }}>
                                                <Button
                                                className={`regularization-button ${selectedItems?.length > 1 ? '' : 'hide'}`}
                                                id='regularization-approve'
                                                onClick={() => handleApproveReject('approved', selectedItems)}>
                                                    <CheckOutlined />
                                                </Button>
                                                <Button
                                                className={`regularization-button ${selectedItems?.length > 1 ? '' : 'hide'}`}
                                                id='regularization-reject'
                                                onClick={() => setOpen(true)}
                                                >
                                                    <CloseOutlined />
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <LoadingSpinner isLoading={isLoading}>
                                <div className="customTbl userManagementTbl scroll-y tblStickyTHead">
                                    <table className="main-table">
                                        <thead >
                                            <tr>
                                                {
                                                    tableHeaders.map((currHeader: any) => {

                                                        if (currHeader === 'select' || currHeader === 'task') {
                                                            return (
                                                                <th 
                                                                style={{ ...tableDataStyle[currHeader]}}
                                                                className={`${currHeader === 'select'? 'colLeftSticky' : ''}`}
                                                                key={currHeader}>
                                                                   {
                                                                    currHeader === 'task'? 
                                                                        ''
                                                                    :
                                                                        <Checkbox onChange={handleSelectAll} 
                                                                        indeterminate={selectedItems.length > 0 && selectedItems?.length !== tableData?.length} 
                                                                        checked={(selectedItems?.length === tableData?.length) && selectedItems.length > 0} 
                                                                        style={{ transform: 'translate(+50%, 0)' }} />
                                                                   }
                                                                </th>
                                                            )
                                                        }
                                                        return (
                                                            <th
                                                            style={{ ...tableDataStyle[currHeader]}}
                                                            className={`${currHeader === 'name'? 'colLeftSticky colLeftStickyLast' : ''}`}
                                                            key={currHeader}>
                                                                {currHeader.split('_').join(" ")}
                                                                {
                                                                    (currHeader === 'name')
                                                                    &&
                                                                    (
                                                                        <Button
                                                                            style={{ border: 'none', outline: 'none', background: 'transparent', padding: '0', margin: '0', 'marginLeft': '5px' }}
                                                                            onClick={() => handleSortClick(currHeader)}
                                                                        >
                                                                            <span className={getIconClass(currHeader, sort)}></span>
                                                                        </Button>
                                                                    )
                                                                }
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                getSortArray(tableData, sort)?.map((currData: any) => {
                                                    return (
                                                        <tr key={currData.id}>
                                                            {
                                                                tableHeaders?.map((header: string, index) => {
                                                                    let dataToShow = ''

                                                                    dataToShow = currData[header.toLowerCase()] || '-';

                                                                    if(header === 'start_date' || header === 'end_date'){
                                                                        dataToShow = getFormattedDate(dataToShow, 'MMMM D, YYYY')
                                                                    }


                                                                    if (header === 'task' || header === 'select') {
                                                                        return (
                                                                            <td 
                                                                            className={`${header === 'select'? 'colLeftSticky' : ''}`}
                                                                            key={header}
                                                                            >
                                                                                {
                                                                                    header === 'task' ?
                                                                                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            <div className='flx' id='regularization-button-container'>
                                                                                                <Button
                                                                                                    className='regularization-button'
                                                                                                    id='regularization-approve'
                                                                                                    onClick={() => handleApproveReject('approved', [currData.id])}
                                                                                                >
                                                                                                    <CheckOutlined style={{ color: 'white' }} />
                                                                                                </Button>
                                                                                                <Button
                                                                                                    className='regularization-button'
                                                                                                    id='regularization-reject'
                                                                                                    onClick={() => setRejectId(currData.id)}
                                                                                                >
                                                                                                    <CloseOutlined style={{ color: 'white' }} />
                                                                                                </Button>
                                                                                                <Button
                                                                                                    onClick={() => handleSelect(currData)}
                                                                                                    style={{ border: 'none', outline: 'none', background: 'transparent', padding: '0', margin: '0' }}>
                                                                                                    <i className='da icon-edit pointer'></i>
                                                                                                </Button>
                                                                                            </div>
                                                                                        </span>
                                                                                        :
                                                                                        <span>
                                                                                            <Checkbox style={{ transform: 'translate(+50%, 0)' }}
                                                                                                onChange={() => handleCheckboxSelect(currData.id, selectedItems)}
                                                                                                checked={selectedItems.includes(currData.id)} />
                                                                                        </span>
                                                                                }
                                                                            </td>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <td 
                                                                        className={`${header === 'name'? 'colLeftSticky colLeftStickyLast' : ''}`}
                                                                        key={header}
                                                                        style={{ ...tableDataStyle[header] }}
                                                                        >
                                                                            <span>{dataToShow}</span>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }

                                            {(!tableData || tableData.length === 0) && <NoDataFound />}
                                        </tbody>

                                        <tfoot></tfoot>
                                    </table>
                                </div>

                                {
                                    tableData?.length > 0 &&
                                    (
                                        <div className="innerHeader" style={{ display: 'flex', justifyContent: 'space-between', height: '25.2px', marginTop: '1rem' }}>
                                            <div></div>
                                            <div style={{ display: 'flex', gap: '1rem' }}>
                                                <Button
                                                    className={`regularization-button ${selectedItems?.length > 1 ? '' : 'hide'}`}
                                                    id='regularization-approve'
                                                    onClick={() => handleApproveReject('approved', selectedItems)}>
                                                    <CheckOutlined />
                                                </Button>
                                                <Button
                                                    className={`regularization-button ${selectedItems?.length > 1 ? '' : 'hide'}`}
                                                    id='regularization-reject'
                                                    onClick={() => setOpen(true)}
                                                >
                                                    <CloseOutlined />
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }
                            </LoadingSpinner>
                        </div>

                        <RejectDrawer
                            open={open}
                            selectedElem={selectedItem ? [selectedItem] : selectedItems}
                            setOpen={setOpen}
                            cancleReject={cancelReject}
                            submitRejectData={handleApproveReject}
                            handleChange={handleChange}
                            rejectData={rejectReason}
                            setRejectData={setRejectReason}
                            title="Work From Home"
                        />
                        <EditAndApproveDrawer
                            cancel={cancelEditAndApprove}
                            wfhData={currentSelected}
                            setWfhData={setCurrentSelected}
                            isWfhOpen={editAndApproveDrawerOpened}
                            setIsWfhOpen={setEditAndApproveDrawerOpened}
                            submitWfhData={handleApproveReject}
                            weekDaysToShow={weekDaysToShow}
                        />
                    </Layout>
            }
        </>

    )
}

export default WfhList;
