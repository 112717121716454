import { DatePicker, Button, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { JoinersListOffboardingService } from "services";

const ExitForm: React.FC<{
  modalTitle: string;
  filterParams: any;
  data: any;
  editUserInfoCancel: any;
  form: any;
  setForm: Function;
  setIsLoading: Function;
  setError: Function;
  getJoinersList: Function;
  signOutWithMessage: Function;
}> = ({
  modalTitle,
  filterParams,
  data,
  editUserInfoCancel,
  form,
  setForm,
  setIsLoading,
  setError,
  getJoinersList,
  signOutWithMessage,
}) => {
    const [checkingEmployeeID, setCheckingEmployeeID] = useState<boolean>(false);
    const [employeeIDExistsInExitQueue, setEmployeeIDExistsInExitQueue] =
      useState<boolean>(false);
    const [employeeIDExistsInZoho, setEmployeeIDExistsInZoho] =
      useState<boolean>(true);
    const [timeoutId, setTimeoutId] = useState<any>(null);

    async function checkEmployeeID(value: any) {
      try {
        setCheckingEmployeeID(true);
        const response: any =
          await new JoinersListOffboardingService().getEmployeeIDExistance({
            employee_id: value,
          });
        setEmployeeIDExistsInExitQueue(response.data.value ? true : false);
        setCheckingEmployeeID(false);
        setError(null);
      } catch (error: any) {
        setCheckingEmployeeID(false);
        setError({
          ...error,
          refreshAction: checkEmployeeID,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    }

    function handleDateChange(date: any, id: string) {
      setForm((prevForm: any) => {
        return {
          ...prevForm,
          [id]: date,
        };
      });
    }

    function handleChange(e: any) {
      setCheckingEmployeeID(true);
      setEmployeeIDExistsInZoho(true);
      const { id, value } = e.target;

      setForm((prevForm: any) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        setTimeoutId(setTimeout(() => checkEmployeeID(value), 900));

        return {
          ...prevForm,
          [id]: value,
        };
      });
    }

    const saveExitMemberInfo = async () => {

      editUserInfoCancel();
      const saveMemberLoadingKey = "exitMemberInfo";
      try {
        message.loading({
          content: "Saving exiting member info...",
          duration: 0,
          key: saveMemberLoadingKey,
          className: "toastMsg loadingMsg",
        });
        const response: any =
          await new JoinersListOffboardingService().saveExitMemberInfo(form);
        if (response.data.msg_id === -1) {
          if (response.data.msg === "EMP_ID_NOT_EXIST") {
            setEmployeeIDExistsInZoho(false);
          }
          return message.error({
            content: "Member info not saved!",
            key: saveMemberLoadingKey,
            duration: 5,
            className: "toastMsg savedError",
          });
        }
        message.success({
          content: "Member info saved successfully!",
          key: saveMemberLoadingKey,
          duration: 5,
          className: "toastMsg savedSuccess",
        });
        setIsLoading(true);
        await getJoinersList(filterParams);
        setIsLoading(false);
        setError(null);
      } catch (error: any) {
        message.error({
          content: "New member info not saved!",
          key: saveMemberLoadingKey,
          duration: 5,
          className: "toastMsg savedError",
        });
        setError({
          ...error,
          refreshAction: saveExitMemberInfo,
        });
        if (error.code === 401) {
          signOutWithMessage("You are not authorized");
        }
      }
    };

    async function handleSubmit(e: any) {
      e.preventDefault();
      if (enableSave()) {
        await saveExitMemberInfo();
      }
    }

    function enableSave() {
      if (
        !employeeIDExistsInExitQueue &&
        form.date_of_exit?.length > 0 &&
        form.employee_id?.length > 0
      ) {
        return true;
      }
      return false;
    }

    useEffect(() => {
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [timeoutId]);

    return (
      <form onSubmit={handleSubmit} id="form">
        <div className="modalCustomBody">
          <div
            className="editUserInfoForm editEvent modalInnerBg"
            style={{ padding: "15px" }}
          >
            <div className="ant-row">
              {modalTitle.startsWith("Add") && form?.exit_status !== true && (
                <div className="ant-col ant-col-xl-24">
                  <div className="inputGroup">
                    <label>
                      Employee ID <span className="req">*</span>
                    </label>
                    <input
                      placeholder="DAIPL/1199/000"
                      type="text"
                      className="ant-input"
                      onChange={handleChange}
                      id="employee_id"
                      value={form.employee_id}
                      autoComplete="false"
                    />
                    {!checkingEmployeeID &&
                      checkingEmployeeID &&
                      !employeeIDExistsInExitQueue &&
                      employeeIDExistsInZoho && (
                        <label
                          className="validdationWrap"
                          style={{ height: "4px" }}
                        >
                          &nbsp;
                        </label>
                      )}

                    {checkingEmployeeID && (
                      <label
                        className="validdationWrap error text-warning"
                        style={{ height: "4px" }}
                      >
                        Checking Employee ID ...
                      </label>
                    )}

                    {!checkingEmployeeID && employeeIDExistsInExitQueue && (
                      <label
                        className="validdationWrap error"
                        style={{ height: "4px" }}
                      >
                        **Employee ID already exists
                      </label>
                    )}

                    {!employeeIDExistsInZoho && (
                      <label
                        className="validdationWrap error"
                        style={{ height: "4px" }}
                      >
                        **Employee ID is not valid
                      </label>
                    )}
                  </div>
                </div>
              )}
              <div className="ant-col ant-col-xl-24">
                <div className="inputGroup">
                  <label>
                    Date of Exit <span className="req">*</span>
                  </label>
                  <DatePicker
                    onChange={(date, dateString) =>
                      handleDateChange(dateString, "date_of_exit")
                    }
                    value={
                      form.date_of_exit
                        ? moment(form.date_of_exit)
                        : (null as any)
                    }
                    className="width-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalCustomFooter">
          <div className="ant-row">
            <div className="ant-col ant-col-xl-24">
              <Button
                className="linkBtn"
                onClick={() => {
                  editUserInfoCancel();
                  setEmployeeIDExistsInExitQueue(false);
                  setEmployeeIDExistsInZoho(true);
                }}
              >
                CLOSE
              </Button>
              <button
                type="submit"
                className="ant-btn btnOk"
                disabled={checkingEmployeeID || !enableSave()}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

export default ExitForm;
