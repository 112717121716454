import { Layout, Menu } from "antd";
import {
  isHamMenuVisible,
  isLeftPanelVisible,
  toggleHamMenu,
  toggleLeftPanel,
} from "models/Slices/GlobalSlice";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { IMenu } from "models/interfaces";
// menuTeamMember
import { useEffect, useState } from "react";
import { LeftNavMenuKeysEnum } from "models/enums/leftNavRoutes";
import Logo from "assets/images/logo-light.svg";
import {
  useGoogleSignIn,
  useTokenID,
  verifyTokenID,
} from "utils/auth/google.security";
import UseLeftPanelLinksFinder from "hooks/UseLeftPanelItemsFinder";
const { Sider } = Layout;



const AppLeftPanel: React.FC<{
  defaultOpenKeys: string[];
  setDefaultOpenKeys: Function;
  selectedKeys: string[];
  setSelectedKeys: Function;
}> = ({
  defaultOpenKeys,
  setDefaultOpenKeys,
  selectedKeys,
  setSelectedKeys,
}) => {
    const { handleSignOut } = useGoogleSignIn();
    const { letSideBarLinks } = UseLeftPanelLinksFinder()
    const leftPanelTEAM = useAppSelector(isLeftPanelVisible);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const hamMenuTEAM = useAppSelector(isHamMenuVisible);

    const [menu, setMenu] = useState<IMenu[]>([]);
    const userInfo = useTokenID();
  
    

    useEffect(() => {
      if (verifyTokenID()) {
        const menu: IMenu[] = letSideBarLinks;
        
        setMenu(
          menu?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((menu) => {
            if (menu.childrens?.length) {
              menu.childrens = menu.childrens.sort(
                (x, y) => x.displayOrder - y.displayOrder
              );
            }
            return menu;
          })
        );
      }
    }, []);

    


    useEffect(() => {
      const path = location.pathname;
      
      if(path.includes('onboarding')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.ONBOARDING]);
      }
      else if(path.includes('offboarding')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.OFFBOARDING]);
      }
      else if(path.includes('team-members')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.TEAM_MEMBERS]);
      }
      else if(path.includes('upload-document') && path.split("/").length <=2){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.UPLOAD_DOCUMENTS]);
      }
      else if(path.includes('report')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.REPORT]);
      }
      else if(path.includes('leave-reconciliation')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.LEAVE_TRACKER]);
      }
      else if(path.includes('biometric')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.BIOMETRIC]);
      }
      else if(path.includes('work-from-home')){
        setDefaultOpenKeys([LeftNavMenuKeysEnum.WORK_FROM_HOME]);
      }

      setSelectedKeys([path]);
    }, [location.pathname]);


    return (
      <Sider
        width={260}
        className={`sidebarSiderWrap mobNav ${hamMenuTEAM ? "TEAM" : ""}`}
        collapsed={leftPanelTEAM}
      >
        <div className="sidebarHeader">
          <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>
          <button
            type="button"
            className={"toggleSidebarBtn" + (leftPanelTEAM ? " TEAM" : "")}
            onClick={() => {
              dispatch(toggleLeftPanel(leftPanelTEAM));
            }}
          >
            <i></i>
          </button>
          <span
            className={`closeSidebarBtn ${hamMenuTEAM ? "TEAM" : ""}`}
            onClick={() => {
              dispatch(toggleHamMenu(hamMenuTEAM));
            }}
          >
            <i className="da icon-close-white" />
          </span>
        </div>
        <div className="sidebarLogo">
          <a href="#/" className="logo" onClick={(e) => e.preventDefault()}>
            <img src={Logo} alt="" title="Ticket Bash" />
          </a>
        </div>
        <Menu
          mode="inline"
          className="leftMenu"
          triggerSubMenuAction={"click"}
          defaultOpenKeys={defaultOpenKeys}
          openKeys={defaultOpenKeys}
          selectedKeys={selectedKeys}
          onOpenChange={(openKeys) => {
            if (openKeys.length > 0) {

              if (openKeys[openKeys.length - 1].toString() === '/biometric-submenu') {
                setDefaultOpenKeys(openKeys);

              } else {
                setDefaultOpenKeys([openKeys[openKeys.length - 1].toString()]);
              }
            } else {
              setDefaultOpenKeys([]);
            }
            setSelectedKeys([location.pathname]);
          }}
          onClick={(a) => {

            setSelectedKeys([a.key]);
            if (a.key === '/totalhoursreport') {
              setDefaultOpenKeys([a.keyPath[2], a.keyPath[1]]);
            }
            else {
              setDefaultOpenKeys([a.keyPath[1]]);
            }

          }}
        >
          {menu &&
            menu.map((menuItem) => {

              if (menuItem.childrens && menuItem.childrens.length > 0) {
                return (
                  <Menu.SubMenu
                    key={menuItem.key}
                    icon={<i className={menuItem.iconClass} />}
                    title={menuItem.menuTitle}
                    onTitleClick={() => {
                      setSelectedKeys(menuItem.key);
                    }}
                  >
                    {menuItem.childrens.map((childItem) => {
                      if (childItem.childrens && childItem.childrens.length > 0) {

                        return (
                          <Menu.SubMenu
                            key={childItem.key}
                            icon={<i className={childItem.iconClass} />}
                            title={childItem.menuTitle}
                            onTitleClick={() => {
                              setSelectedKeys(childItem.key);
                            }}
                          >
                            {childItem.childrens.map((grandChild) => {

                              return (
                                <Menu.Item key={grandChild.key} icon={<i className={grandChild.iconClass} />} >
                                  {grandChild.navigationLink ? (
                                    <Link to={grandChild.navigationLink}>
                                      {grandChild.menuTitle}
                                    </Link>
                                  ) : (
                                    <span>{grandChild.menuTitle}</span>
                                  )}
                                </Menu.Item>)
                            })}
                          </Menu.SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item
                            key={childItem.key}
                            icon={<i className={childItem.iconClass} />}
                          >
                            {childItem.navigationLink ? (
                              <Link to={childItem.navigationLink}>
                                {childItem.menuTitle}
                              </Link>
                            ) : (
                              <span>{childItem.menuTitle}</span>
                            )}
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu.SubMenu>
                );
              } else {
                return (
                  <Menu.Item
                    key={menuItem.key}
                    icon={<i className={menuItem.iconClass} />}
                  >
                    {menuItem.navigationLink ? (
                      <Link to={menuItem.navigationLink}>
                        {menuItem.menuTitle}
                      </Link>
                    ) : (
                      <span>{menuItem.menuTitle}</span>
                    )}
                  </Menu.Item>
                );
              }
            })}

        </Menu>
        <div className="leftPanelFooter forMob">
          <span className="avatarIcon" title={userInfo?.sub?.name}>
            <i className="da icon-user-w" />
          </span>
          <span className="logOut" onClick={() => handleSignOut()}>
            Logout
          </span>
        </div>
      </Sider>
    );
  };

export default AppLeftPanel;
