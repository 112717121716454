import React, { useState, useEffect, useMemo } from 'react';
import { getFormattedName, getSortArray } from 'utils/commonFunction';
import { Timesheet, filter } from 'models/interfaces';
import TimesheetTable from './TimesheetTable';
import { Constants } from 'views/constants';
import DateRangePicker from './DateRangePicker';
import { RangeValue } from 'models/types/Reports/DateRangeSelector';
import moment from 'moment';
import { Button, Layout } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import type { Moment } from 'moment';
import { usePageTitle } from 'hooks';
import ExcelJS from 'exceljs';
import { useApiServices } from 'hooks/useApiServices';

interface tableDataInterface { name: string, timesheet: string[] }


const handleDatePicker = (): filter => {
    const date = new Date();
    if (date.getDate() === 1) {
        return {
            startDay: new Date(date.getFullYear(), date.getMonth()).getDate(),
            endDay: new Date(date.getFullYear(), date.getMonth(), 0).getDate(),
            month: date.getMonth(),
            year: date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear()
        }
    }
    return {
        startDay: new Date(date.getFullYear(), date.getMonth() + 1).getDate(),
        endDay: date.getDate() - 1,
        month: date.getMonth() + 1,
        year: date.getFullYear()
    }
}


const TimesheetDelayReport = () => {

    usePageTitle(Constants.TIMESHEET_TITLE);

    const [dateFilter, setDateFilter] = useState<filter>(handleDatePicker());
    const [selectedRange, setSelectedRange] = useState<RangeValue>(null);
    const [dates, setDates] = useState<RangeValue>(null);
    const [finalDates, setFinalDates] = useState<RangeValue>(null);
    const [diffMonth, setDiffMonth] = useState<boolean>(false);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
    const { isLoading, data, isError, fetchData } = useApiServices();


    const tableHeaders = useMemo(() => {
        const dayArr: number[] = []

        for (let i = dateFilter.startDay; i <= dateFilter.endDay; i++) {
            const dayNo = new Date(dateFilter.year, dateFilter.month - 1, i).getDay();
            if (dayNo !== 0 && dayNo !== 6) {
                dayArr.push(i);
            }
        }
        return dayArr;
    }, [dateFilter]);

    const [tableData, setTableData] = useState<any []>([]); 


    const disabledDate = (current: Moment) => {
        if (current.isSameOrAfter(moment(), 'day') || current.day() === 0 || current.day() === 6) return true;  //weekends

        if (!dates) {
            return false;
        }
        // only start or only end is selected
        if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
            if (dates[0] && !current.isSame(dates[0], 'month')) return true; // not in starting month
            if (dates[1] && !current.isSame(dates[1], 'month')) return true; // not in ending month
        }
        else {
            return false;
        }
        return false;
    };


    // HANDLING THE RANGE SELECT OR DELETE
    const handleRangeChange = (range: RangeValue) => {
        if (range?.[0] && range[1] && range[0].month() === range[1].month()) {
            const startDay = range[0].date();
            const endDay = range[1].date();
            const month = range[0].month() + 1;
            const year = range[0].year();
            setDateFilter({ startDay, endDay, month, year });
        }
        if (range?.[0] && range[1]) {
            if (dates === null && range[0].month() !== range[1].month() && finalDates) {
                return;
            }
        }
        setSelectedRange(range);
    }


    const handleCalanderChange = (range: RangeValue) => {
        const dateTemp = dates;
        setDates(null)
        setSelectedRange(null)
        if (range === null) {
            setDiffMonth(false)
            setFinalDates(range)
        }
        if (range?.[0] && range[1]) {
            if (dateTemp === null && range[0].month() !== range[1].month() && finalDates) {
                range = finalDates[0]?.month() === range[0].month() ? [null, range[1]] : [range[0], null];
                setSelectedRange(range)
                setDates(range)
                if (diffMonth) setDiffMonth(false)
                return;
            } else {
                setFinalDates(range);
                setDates(null);
                return;
            }
        }
        else if (range && ((range[0] && !range[1]) || (!range[0] && range[1]))) {
            if (dateTemp === null && finalDates) {
                if (diffMonth) setDiffMonth(false)
            }
        }
        setSelectedRange(range)
        setDates(range)
    }


    const onPanelChange = (value: RangeValue) => {
        if (finalDates?.[0] && finalDates[1]) {
            if (value?.[0] && value[1]) {

                let bool = false;
                if (!value[0].isSame(value[1], 'month')) {
                    bool = true;
                }
                setDiffMonth(bool)
            } else { // both the starting and ending date is not selected
                setDiffMonth(false)
            }

        } else {
            setDiffMonth(false)
        }
    }


    // RESTRACTURING THE TABLE DATAS
    function buildingTableDatas(data: any, tableHeaders: number[]) {

        if(!data?.timesheet_delay_data || data.timesheet_delay_data.length === 0) return []
        
        const updatedData = data.timesheet_delay_data.map((emp_timesheet: Timesheet) => {
            let weekDaysData: string[] = [];

            tableHeaders.forEach((curr_weekday) => {
                if (emp_timesheet[`day_${curr_weekday}`] === null)
                    weekDaysData.push('');
                else
                    weekDaysData.push(emp_timesheet[`day_${curr_weekday}`]);
            })

            return {
                name: getFormattedName(emp_timesheet['name'], '.'),
                timesheet: weekDaysData
            }
        });
        return getSortArray(updatedData, { keyToSort: 'name', direction: 'asc' })
        
    }


    const exportToExcelFile = (data: any[]) => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0'); // Get day with leading zero if needed
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get month with leading zero if needed (Note: January is 0)
        const year = currentDate.getFullYear(); // Get full year (4 digits)
        const formattedDate = `${day}-${month}-${year}`;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Timesheet Delay Report");

        const headerColumn: Partial<ExcelJS.Column>[] | { header: any; key: any; width: number; }[] = [];

        const array = ['Name', ...tableHeaders]
        const monthName = new Date(dateFilter.year, dateFilter.month - 1).toLocaleString('default', { month: 'long' }).substring(0, 3)
        const headerColumns: string[] = array.map(el => el !== 'Name' && typeof el === 'number' ? `${monthName} ${el < 10 ? '0' + el : el}` : el);

        const headers = headerColumns.map(key => {
            return key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
        });


        headers.forEach((header: string) => {
            let obj = {
                header: header,
                key: header,
                width: header === 'Name' ? 30 : 8
            }
            headerColumn.push(obj)
        });

        sheet.columns = headerColumn;

        data?.forEach((curr_data: any) => {
            curr_data.timesheet = curr_data.timesheet.map((value:any) => value === 0 ? '' : value);
            const updated_data = [curr_data.name, ...curr_data.timesheet]
            const formattedItem = {};
            headerColumns.forEach((key, index) => {
                const header = key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()); // Key that matches the headers
                formattedItem[header] = updated_data[index]; // Value from the original item
            });
            const row = sheet.addRow(formattedItem);
            row.alignment = { wrapText: true, vertical: 'top', horizontal: 'right' };
            row.getCell(1).alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
            let a: any [] = []
            row.eachCell((cell, cellNumber) => {
                let cellValue = cell.value;
                if(cellValue && typeof(cellValue) == 'number' && Number(cellValue) > 7){
                    cell.fill = {
                        type: 'pattern',
                        pattern: "solid",
                        fgColor: { argb: 'd80b86' }
                    }
                    cell.font = {
                        color: { argb: 'ffffff' } 
                    }
                }
            });
        })

        sheet.getRow(1).font = { bold: true };
        sheet.getRow(1).alignment = { vertical: 'top', horizontal: 'left' };

        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: "application/vvnd.openxmlformats-officedocument.spreadsheet.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = `timesheet_delay_report_${formattedDate}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    
    // FETCH THE TIMESHEET DELAY DATA OF THE SELECTED MONTH OR YEAR CHANGED
    useEffect(() => {
        if(data){
            const structuredData  = buildingTableDatas(data, tableHeaders)
            setTableData(structuredData);
        }
    }, [data, tableHeaders]);

    // FETCH THE TIMESHEET DELAY DATA OF THE SELECTED MONTH OR YEAR CHANGED
    useEffect(() => {
        fetchData('GET_TIMESHEET_DELAY_DATA', { month: dateFilter.month, year: dateFilter.year });
    }, [dateFilter.year, dateFilter.month, fetchData]);

    return (
        <>
            {
                isError ? (
                    <React.Fragment>
                        <h2>{isError.code} Error</h2>
                        <div className="errorContent">
                            <div className="errorContentInner">
                                <span className="error-img"></span>
                                <div>
                                    <h3>{isError.message}</h3>
                                    <p>{Constants.BACK_TO_PREVIOUS_PAGE}</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (

                    <Layout className="mainInnerLayout timesheetDelayReport">
                        <h2>{Constants.TIMESHEET_REPORT}</h2>
                        <div className="innerHeader" >
                            <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                                <div className="ant-col ant-col-xl-12 flx justifyContentBetween alignItemsCenter">
                                    <h3>
                                        {

                                            new Date(dateFilter.year, dateFilter.month - 1)
                                                .toLocaleString('default', { month: 'long' })
                                                .substring(0, 3)
                                        }
                                        ,
                                        <span style={{ marginLeft: '.5rem' }}>
                                            {
                                                dateFilter.year
                                            }
                                        </span>
                                    </h3>
                                </div>

                                <div className='flx ant-col-xl-12' style={{ justifyContent: 'flex-end' }}>
                                    <div className="innerHeaderRight flx alignItemsFlexEnd">
                                        <div style={{ 'marginRight': '18px' }}>
                                            <DateRangePicker
                                            disabled={isLoading}
                                            value={selectedRange}
                                            handleRangeChange={handleRangeChange}
                                            disabledDate={disabledDate}
                                            dates={dates}
                                            setDates={setDates}
                                            finalDates={finalDates}
                                            setFinalDates={setFinalDates}
                                            diffMonth={diffMonth}
                                            setDiffMonth={setDiffMonth}
                                            handleCalanderChange={handleCalanderChange}
                                            onPanelChange={onPanelChange}
                                            isDropdownOpened={isDropdownOpened}
                                            setIsDropdownOpened={setIsDropdownOpened}
                                            />
                                        </div>

                                        <div >
                                            <Button
                                            disabled={isLoading}
                                            className="addUserBtn mr-10 custom-export"
                                            icon={
                                                <DownloadOutlined style={{ color: 'white', 'paddingTop': '3px' }}
                                                    onPointerOverCapture={undefined}
                                                    onPointerMoveCapture={undefined} />
                                            }
                                            onClick={() => exportToExcelFile(tableData)}>
                                                Export to XLSX
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <TimesheetTable
                                isTableLoading={isLoading}
                                tableHeaders={tableHeaders}
                                tableData={tableData}
                            />
                        </div>
                    </Layout>
                )
            }
        </>
    );
};

export default TimesheetDelayReport; 