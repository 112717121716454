import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Button, Layout, message, Upload } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { LeaveReconcilliationService } from 'services';
import { auth } from 'utils/auth/google.security';
import { getEncodedData } from 'utils/commonFunction';
import { Constants } from 'views/constants';
const { Dragger } = Upload;


const UploadLeaveDocument: React.FC = () => {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const history = useHistory();
    const [error, setError] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<string>('');

    const signOutWithMessage = (value: string) => {
        auth.signOut();
        window.localStorage.removeItem("emailId");
        window.localStorage.removeItem("displayName");
        window.localStorage.removeItem("expirationTime");
        window.localStorage.removeItem("authToken");
        window.localStorage.removeItem("employee_code");
        const msg = getEncodedData(value);
        return history.push(`/login?msg=${msg}`);
    };

    const handleUpload = async () => {
        try {
            const formData = new FormData();

            if (fileList?.[0])
                formData.append('file', fileList[0] as unknown as Blob)

            setUploading(true)


            const hide = message.loading({
                content: "Submitting CSV File",
                duration: 0,
                key: 'sendingCsvDocument',
                className: "toastMsg loadingMsg",
            });

            const response: any = await new LeaveReconcilliationService().postLeaveData(formData, { date_for: selectedDate });

            setUploading(false)

            if (response.data.msg_id === -1) {
                message.error({
                    content: "error in submitting file",
                    duration: 5,
                    key: 'sendingCsvDocument',
                    className: "toastMsg savedError",
                });
            }
            else {
                hide();
                message.success({
                    content: "Submitted file successfully",
                    duration: 5,
                    key: 'SubmitRequestSend',
                    className: "toastMsg loadingMsg"
                });

                setFileList([]);
                setSelectedDate('');
            }
        } catch (error: any) {
            console.error('Error uploading file:', error);
            if (error.code === 401) {
                signOutWithMessage("You are not authorized");
            }
            setError({
                ...error,
                refreshAction: handleUpload,
            });
            message.error({
                content: "Sending CSV document",
                duration: 5,
                key: 'sendingCsvDocument',
                className: "toastMsg savedError",
            });
        }
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,

        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },

        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
    };



    return (
    <Layout className="mainInnerLayout full-screen uploadDocument">
        <div className="innerHeader headerSingle">
            <div className="ant-row alignItemsCenter flx justifyContentBetween alignItemsCenter">
                <div className="ant-col ant-col-xl-16 flx justifyContentBetween alignItemsCenter" style={{ marginBottom: '20px' }}>
                    <h3>{Constants.UPLOAD_LEAVE_DOCUMENTS}</h3>
                </div>
            </div>
            <form encType="multipart/form-data">
                <Dragger {...props} accept='.csv, .xlxs' maxCount={1}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={(fileList.length === 0 || uploading)}
                    style={{ marginTop: 16 }}
                >
                    Upload
                </Button>
            </form>
        </div>
    </Layout>
    )
}
export default UploadLeaveDocument;
